import dripApiService from "../services/drip-api-service";

export default {
	install(Vue) {
		Vue.prototype.$submitDripGuide = (
			userEmail,
			userDno,
			userJourney,
			userPostcode,
			userSector
		) => {
			// Create and Format a New Subscriber
			const subscriber = {
				email: userEmail,
				new_email: userEmail,
				time_zone: "Europe/London",
				custom_fields: {
					postcode: userPostcode,
					sector: userSector
				},
				tags: ["online_tool_complete"]
			};

			// Add User DNO Custom Fields
			if (userDno) {
				subscriber.custom_fields["dno_name"] = userDno.name;
				subscriber.custom_fields["dno_telephone"] = userDno.phone;
				subscriber.custom_fields["dno_website"] = userDno.url.url;
			}

			// Add User Journey Custom Fields
			if (userJourney) {
				subscriber.custom_fields["generator_type"] = userJourney.name;
				subscriber.custom_fields["journey_code"] = userJourney.uid;
			}

			// Create/Update Submit Subscriber
			return dripApiService.createUpdateSubscriber(JSON.stringify(subscriber));
		};
	}
};
