import Vue from "vue";
import Vuex from "vuex";

import prismicPage from "@/store/prismic-page";
import prismicGuide from "@/store/prismic-guide";
import guideUser from "@/store/guide-user";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		prismicPage,
		prismicGuide,
		guideUser
	}
});
