import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueGtm from "@gtm-support/vue2-gtm";

import PrismicVue from "@prismicio/vue";
import linkResolver from "./link-resolver";
import VueMeta from "vue-meta";
import VueMoment from 'vue-moment'

import "@/assets/scss/_style.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faEnvelope,
	faHandshake,
	faPlus,
	faUsers,
	faLock,
	faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope as farEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
	faFacebookF,
	faTwitter,
	faInstagram,
	faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

//Brands
library.add(faFacebookF);
library.add(faTwitter);
library.add(faInstagram);
library.add(faLinkedinIn);

//Solid
library.add(faEnvelope);
library.add(faHandshake);
library.add(faPlus);
library.add(faUsers);
library.add(faLock);
library.add(faMapMarkerAlt);

//Regular
library.add(farEnvelope);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(VueMoment)
var vueAwesomeCountdown = require('vue-awesome-countdown').default;

Vue.use(vueAwesomeCountdown);

// const isProd = process.env.NODE_ENV === "production";
//

Vue.use(VueGtm, {
	id: "GTM-NCPL626", // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
	defer: true, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
	debug: false, // Whether or not display console logs debugs (optional)
	vueRouter: router // Pass the router instance to automatically sync with router (optional)
});

const accessToken = ""; // Leave empty if your repo is public
const endpoint = "https://alomcp-landing.cdn.prismic.io/api/v2"; // Use your repo name

Vue.use(PrismicVue, {
	endpoint,
	apiOptions: { accessToken },
	linkResolver
});

Vue.use(VueMeta);

Vue.prototype.$lang = navigator.languages
	? navigator.languages[0]
	: navigator.language || navigator.userLanguage;

// Custom plugins
import DripFormPlugin from "./plugins/drip-form.js";
Vue.use(DripFormPlugin);

import DnoLookupPlugin from "./plugins/dno-lookup.js";
Vue.use(DnoLookupPlugin);

import DripApiPlugin from "./plugins/drip-api.js";
Vue.use(DripApiPlugin);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
