/* eslint-disable no-console */
import Vue from "vue";
import { EventEmitter } from "events";

class DnoLookupService extends EventEmitter {
	constructor() {
		super();
	}

	/**
	 * Method to submit a get request to the netlify serverless function for dno lookups
	 */
	async getDnoByPostcode(postcode) {
		const endpoint = `/.netlify/functions/dno-postcode-lookup?postcode=${postcode}`;

		// prettier-ignore
		return await Vue.axios
			.get(endpoint)
			.catch(error => {
				console.error(error);
				return {};
			});
	}
}

const dnoLookupService = new DnoLookupService();

dnoLookupService.setMaxListeners(5);

export default dnoLookupService;
