<template>
	<div v-if="isLoading" class="loading">
		<div class="loading-container">
			<LoadingIcon />
			<img src="@/assets/icons/logo.png" alt="loading icon" />
		</div>
	</div>
</template>

<script>
import LoadingIcon from "@/components/icons/Loading.vue";
export default {
	name: "loading",
	props: ["isLoading"],
	components: { LoadingIcon }
};
</script>

<style lang="scss">
.loading {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 9999;
	background: rgba(0, 0, 0, 0.8);
	overflow-y: hidden;
	&-container {
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	img {
		position: absolute;
		border-radius: 50%;
		width: 75px;
		height: 75px;
		border-radius: 50%;
	}
	&-icon {
		animation: rotation 2s infinite linear;
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
</style>
