<template>
	<div>
		<!--- Icon --->
		<div class="position-absolute top-0 right-0">
			<div
				class="w-32 h-32 lg:w-48 lg:h-48 quartercircle-yellow quartercircle-right"
			></div>
			<div class="position-absolute top-0 right-0 z-10">
				<prismic-image
					class="h-16 lg:h-24 w-16 lg:w-24 mr-3 mt-3"
					:field="icon"
				/>
			</div>
		</div>

		<!--- Content --->
		<div class="mr-24">
			<h2 class="h5 mb-2 font-weight-bold">{{ section.heading }}</h2>
			<h3 v-if="section.actionRequired" class="h6 text-danger font-weight-bold">
				Action Required
			</h3>
			<prismic-rich-text
				class="prismic-small-copy prismic-line-breaks mt-4"
				:field="section.text"
			/>

			<div v-if="section.popupToggled && section.modals" class="w-100">
				<Modal
					:btnText="section.popupText"
					:modals="section.modals"
					:totalModals="section.modals.length"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import Modal from "@/components/Modal.vue";
export default {
	name: "ActionSection",
	components: {
		Modal
	},
	props: ["section", "icon"]
};
</script>
