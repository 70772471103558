<template>
	<div class="guide-section transition-500">
		<GuideNav />

		<Loading :is-loading="loading.route || loading.section" />
		<!-- steps 2 -->
		<!--- Main and Background --->
		<div class="guide-background with-sun"></div>

		<main class="container flex-auto">
			<div  v-if="!loading.route && !loading.section" class="container relative pb-4 d-flex justify-content-center">
				<Pagination stepIndex="2" name="middle" max="0" />
			</div>
			<!--- Card Wrapper --->
			<div
				v-if="!loading.route && !loading.section"
				class="guide-card w-100 w-md-75 w-2xl-60"
			>
				<div class="py-2 pl-4">
					<!--- Section Component --->
					<component
						v-if="section"
						:is="section.component"
						:section="section"
						:icon="getUserJourney.icon"
					/>
					<!--- Section Buttons --->
					<div class="mt-5 d-flex">
						<button
							@click="previousSection"
							class="mr-2 btn btn-rounded btn-outline-primary guide-button"
						>
							<small>Back</small>
						</button>
						<button
							v-if="checkSectionButton()"
							@click="nextSection"
							class="btn btn-rounded btn-primary guide-button"
						>
							<small>{{section.nextButtonText ? section.nextButtonText : "Next"}}</small>
						</button>
					</div>
				</div>
			</div>
		</main>

		<Footer v-if="options.footer" :data="options.footer" />
	</div>
</template>

<script>
import GuideNav from "@/components/guide/nav/GuideNav.vue";

import Loading from "@/components/Loading.vue";

import Footer from "@/components/Footer.vue";
import Pagination from "@/components/Pagination.vue";

import ActionSection from "@/components/guide/sections/ActionSection.vue";
import ContentSection from "@/components/guide/sections/ContentSection.vue";

import { mapGetters } from "vuex";

export default {
	name: "guide-section",
	components: {
		GuideNav,
		Loading,
		Pagination,
		Footer
	},
	created() {
		this.getContent();
	},
	beforeDestroy() {
		this.removeWindowEventListeners();
	},
	data() {
		return {
			section: null,
			sectionIndex: 0,
			options: {
				footer: {
					topLinks: [],
					twitterLink: {},
					facebookLink: {},
					instagramLink: {},
					linkedinLink: {},
					privacyLink: {},
					termsLink: {}
				}
			},
			loading: {
				section: true,
				route: false
			}
		};
	},
	watch: {
		"$route.params.jouney_section_id": function() {
			this.getContent();
		}
	},
	computed: {
		...mapGetters({
			getPrismicPage: "prismicPage/getPrismicPageByName",
			getUserExists: "guideUser/getUserExists",
			getUserJourney: "guideUser/getUserJourney"
		})
	},
	methods: {
		getContent() {
			// Check the User
			if (!this.getUserExists) {
				this.$router.push({ name: "guide" });
				return;
			}

			// Set Loading
			this.loading.section = true;

			// Set SectionIndex
			this.sectionIndex = this.$route.params["jouney_section_id"]
				? this.$route.params["jouney_section_id"]
				: 0;

			// Find Section
			this.section = this.getUserJourney.sections[this.sectionIndex];

			// Check the Section
			if (!this.section) {
				this.$router.push({ name: "guide" });
				return;
			}

			// Set Components
			switch (this.section.type) {
				case "action_section":
					this.section["component"] = ActionSection;
					break;

				default:
					this.section["component"] = ContentSection;
					break;
			}

			// Check and after-content
			if (this.section) this.afterContent();
		},
		afterContent() {
			// Get and Parse Options from Store
			this.parseOptions(this.getPrismicPage("options_page"));

			// Set Loading
			this.loading.section = false;

			// Add Window Event Listeners
			this.addWindowEventListeners();
		},

		parseOptions(options) {
			if (options) {
				//Footer
				this.options.footer.topLinks = options.data.top_links;
				this.options.footer.twitterLink = options.data.twitter_link;
				this.options.footer.facebookLink = options.data.facebook_link;
				this.options.footer.instagramLink = options.data.instagram_link;
				this.options.footer.linkedinLink = options.data.linkedin_link;
				this.options.footer.privacyLink = options.data.privacy_policy_link;
				this.options.footer.termsLink = options.data.terms_link;
			}
		},

		keyUpChangeSectionEvent(event) {
			if (event.code == "Enter") this.nextSection();
			if (event.code == "Backspace") this.previousSection();
		},
		addWindowEventListeners() {
			// Key-Up Listener
			window.addEventListener("keyup", this.keyUpChangeSectionEvent);
		},
		removeWindowEventListeners() {
			// Key-Up Listener
			window.removeEventListener("keyup", this.keyUpChangeSectionEvent);
		},

		async previousSection() {
			// Set Loading
			this.loading.route = true;

			// Remove Window Event Listeners
			this.removeWindowEventListeners();

			// Check if it's the First SectionIndex
			if (this.sectionIndex == 0) await this.$router.push({ name: "guide" });
			// Check if it's the Second SectionIndex
			else if (this.sectionIndex == 1)
				await this.$router.push({
					name: "guide-section",
					params: {
						journey_uid: this.$route.params["journey_uid"]
					}
				});
			// All Other SectionIndex's
			else
				await this.$router.push({
					name: "guide-section-id",
					params: {
						journey_uid: this.$route.params["journey_uid"],
						jouney_section_id: this.sectionIndex - 1
					}
				});

			// Set Loading
			this.loading.route = false;
		},
		async nextSection() {
			// Set Loading
			this.loading.route = true;

			// Remove Window Event Listeners
			this.removeWindowEventListeners();

			// Check if it's the Last SectionIndex
			if (this.getUserJourney.sections.length <= this.sectionIndex + 1)
				this.$router.push({
					name: "guide-next-steps",
					params: {
						journey_uid: this.$route.params["journey_uid"]
					}
				});
			// All Other SectionIndex's
			else
				this.$router.push({
					name: "guide-section-id",
					params: {
						journey_uid: this.$route.params["journey_uid"],
						jouney_section_id: this.sectionIndex + 1
					}
				});

			// Set Loading
			this.loading.route = false;
		},

		checkSectionButton() {
			switch (this.section.type) {
				case "action_section":
					if (!this.section.actionRequired) return false;
					break;

				default:
					break;
			}

			// Return Valid
			return true;
		}
	}
};
</script>

<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.guide-section {
	background-color: $secondary;
	min-height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: stretch;

	main {
		padding-top: 2rem;
		padding-bottom: 2rem;
		padding-left: 0;
		padding-right: 0;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(lg) {
			min-height: 100vh;
		}
	}
}
</style>
