<template>
	<svg
		preserveAspectRatio="xMidYMin slice"
		height="108px"
		viewBox="0 0 66 66"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		role="status"
		class="loading-icon"
	>
		<g
			id="mobile"
			stroke="none"
			stroke-width="1"
			fill="none"
			fill-rule="evenodd"
		>
			<g id="03.-loader" transform="translate(-139.000000, -393.000000)">
				<g id="loader" transform="translate(140.000000, 394.000000)">
					<circle id="Oval-2" stroke="#DFDFE4" cx="32" cy="32" r="32"></circle>
					<path
						d="M60.0276544,47.4540868 C62.5591477,42.8727277 64,37.6046284 64,32 C64,14.326888 49.673112,0 32,0"
						id="Oval-2"
						stroke="#d6e342"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					></path>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {};
</script>

<style></style>
