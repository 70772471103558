<template>
  <footer>
    <div class="bg-primary pt-md-5 clip-footer">
      <div class="container">
        <div class="row text-center py-5">
          <div class="col-12 my-3" v-for="(item, index) in data.topLinks" :key="'top-link-' + index">
            <prismic-link class="text-white font-weight-bold" :field="item.link">{{ item.text }}</prismic-link>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-dark">
      <div class="container">
        <!-- <div class="row pt-4 pb-3">
          <div class="col-12">
            <ul class="nav justify-content-center">
              <li class="nav-item">
                <prismic-link class="nav-link text-white" :field="data.twitterLink">
                  <font-awesome-icon :icon="['fab', 'twitter']" />
                </prismic-link>
              </li>
              <li class="nav-item">
                <prismic-link class="nav-link text-white" :field="data.facebookLink">
                  <font-awesome-icon :icon="['fab', 'facebook-f']" />
                </prismic-link>
              </li>
              <li class="nav-item">
                <prismic-link class="nav-link text-white" :field="data.instagramLink">
                  <font-awesome-icon :icon="['fab', 'instagram']" />
                </prismic-link>
              </li>
              <li class="nav-item">
                <prismic-link class="nav-link text-white" :field="data.linkedinLink">
                  <font-awesome-icon :icon="['fab', 'linkedin-in']" />
                </prismic-link>
              </li>
            </ul>
          </div>
        </div> -->
        <div class="row text-center py-4 font-70">
          <div class="col-12">
            <p class="font-weight-bold text-white">&copy;{{ new Date().getFullYear() }} National Grid ESO | <prismic-link class="text-white" :field="data.privacyLink">Privacy Policy</prismic-link></p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  props: ['data'],
  created() {
    this.getContent();
  },
  data() {
    return {
      fields: {
        footer: {
          socials: null,
          links: null
        }
      }
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    }
  },
  methods: {
    getContent() {
      this.$prismic.client
        .query(
          this.$prismic.Predicates.at("document.type", "options_page"),

          { lang: this.$lang }
          // { lang: "es-es" }
        )
        .then(data => {
          let document =
            data.results !== undefined && data.results.length > 0
              ? data.results[0]
              : null;
          if (document !== null) {
            this.parseDocument(document);
          } else {
            this.$prismic.client.getSingle("options_page").then(document => {
              this.parseDocument(document);
            });
          }
          this.afterContent();
        });
    },
    afterContent() {},
    parseDocument(document) {
      if (document) {
        // Socials
        this.fields.footer.socials = document.data.footer_socials;

        // Links
        this.fields.footer.links = document.data.footer_links;
      }
    }
  }
};
</script>
<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.clip-footer {
  @include media-breakpoint-up(md) {
    clip-path: ellipse(100% 100% at 50% 100%);
  }
}

</style>