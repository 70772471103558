import Vue from "vue";
import Router from "vue-router";

import Home from "./views/Home.vue";
import Preview from "./views/Preview.vue";
import NextSteps from "./views/NextSteps.vue";
import NotFound from "./views/NotFound.vue";

import Guide from "./views/guide/Guide.vue";
import GuideSection from "./views/guide/GuideSection.vue";
import GuideNextSteps from "./views/guide/GuideNextSteps.vue";

import GuideSuccess from "./views/guide/Success.vue";

Vue.use(Router);

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		/*
			Home
		*/
		{
			path: "/",
			name: "home",
			component: Home
		},
		/*
			Guide
		*/
		{
			path: "/guide",
			name: "guide",
			component: Guide
		},

		{
			path: "/guide/:journey_uid",
			name: "guide-section",
			component: GuideSection
		},
		{
			path: "/guide/:journey_uid/:jouney_section_id",
			name: "guide-section-id",
			component: GuideSection
		},
		{
			path: "/guide/:journey_uid/next-steps",
			name: "guide-next-steps",
			component: GuideNextSteps
		},
		{
			path: "/guide/:journey_uid/success",
			name: "guide-success",
			component: GuideSuccess
		},
		/*
			Next Steps
		*/
		{
			path: "/next-steps",
			name: "next-steps",
			component: NextSteps
		},
		/*
			Prismic Preview
		*/
		{
			path: "/preview",
			name: "preview",
			component: Preview
		},
		

		/*
			404
		*/
		{
			path: "/not-found",
			name: "not-found",
			component: NotFound
		},
		/*
			Redirects
		*/
		{ path: "*", redirect: { name: "not-found" } }
	],

	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				offset: { x: 0, y: 10 }
			};
		} else if (savedPosition) {
			return savedPosition;
		} else {
			document.getElementById("app").scrollIntoView();
			return { x: 0, y: 0 };
		}
	}
});

//https://alligator.io/vuejs/vue-router-modify-head/
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find(r => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find(r => r.meta && r.meta.metaTags);
	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find(r => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(
		document.querySelectorAll("[data-vue-router-controlled]")
	).map(el => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		// Add the meta tags to the document head.
		.forEach(tagDef => {
			const tag = document.createElement("meta");

			Object.keys(tagDef).forEach(key => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create, so we don't interfere with other ones.
			tag.setAttribute("data-vue-router-controlled", "");

			document.head.appendChild(tag);
		});

	next();
});

function appUrl() {
	return process.env.VUE_APP_APP_URL;
}

function createMetaTags(title, description, img) {
	return {
		title: title,
		metaTags: [
			{
				name: "description",
				content: description
			},
			{
				property: "og:title",
				content: title
			},
			{
				property: "og:description",
				content: description
			},
			{
				property: "og:image",
				content: process.env.VUE_APP_URL + img
			},
			{
				property: "og:url",
				content: process.env.VUE_APP_URL
			},
			{
				property: "twitter:card",
				content: "summary_large_image"
			},
			{
				property: "twitter:title",
				content: title
			},
			{
				property: "twitter:description",
				content: description
			},
			{
				property: "twitter:image",
				content: process.env.VUE_APP_URL + img
			}
		]
	};
}

export default router;
