import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const prismicGuide = {
	namespaced: true,
	state: {
		prismicJourneys: new Map(),
		prismicDnos: new Map()
	},
	getters: {
		// Normal GETs for the Maps
		getPrismicJourneys: state => state.prismicJourneys,
		getPrismicJourneyByUid: state => prismicJourney =>
			state.prismicJourneys.get(prismicJourney),

		getPrismicDnos: state => state.prismicDnos,
		getPrismicDnoByUid: state => prismicDno =>
			state.prismicDnos.get(prismicDno),

		// Functionality GETs
		getJSON: state =>
			JSON.stringify({
				prismicJourneys: Array.from(state.prismicJourneys.entries())
			})
	},
	mutations: {
		setPrismicJourney(state, payload) {
			state.prismicJourneys.set(payload.uid, payload.document);
		},

		setPrismicDno(state, payload) {
			state.prismicDnos.set(payload.uid, payload.document);
		}
	},
	actions: {
		fetchPrismicJourneys({ commit }, prismicPromise = null) {
			return new Promise(async (resolve, reject) => {
				// Default Prismic Promise
				if (!prismicPromise)
					prismicPromise = Vue.prototype.$prismic.client.query(
						Vue.prototype.$prismic.Predicates.at(
							"document.type",
							"guide_journey"
						),
						// { lang: Vue.prototype.$lang }
						// { lang: "es-es" }
						{ pageSize: 100 }
					);

				// Default Documents Return
				const documents = [];

				// Wait for Promise
				const data = await prismicPromise;

				// Check Response
				if (data.results !== undefined && data.results.length)
					// Loop Over and Store Sections
					data.results.forEach(document => {
						commit("setPrismicJourney", {
							uid: document.uid,
							document
						});
						documents.push(document.uid);
					});

				// Return Null or Document IDs
				resolve(documents);
			});
		},

		fetchPrismicDnos({ commit }, prismicPromise = null) {
			return new Promise(async (resolve, reject) => {
				// Default Prismic Promise
				if (!prismicPromise)
					prismicPromise = Vue.prototype.$prismic.client.query(
						Vue.prototype.$prismic.Predicates.at("document.type", "guide_dno"),
						// { lang: Vue.prototype.$lang }
						// { lang: "es-es" }
						{ pageSize: 100 }
					);

				// Default Documents Return
				const documents = [];

				// Wait for Promise
				const data = await prismicPromise;

				// Check Response
				if (data.results !== undefined && data.results.length > 0)
					// Loop Over and Store Sections
					data.results.forEach(document => {
						commit("setPrismicDno", {
							uid: document.uid,
							document: document
						});
						documents.push(document.uid);
					});

				// Return Null or Document IDs
				resolve(documents);
			});
		}
	}
};

export default prismicGuide;
