<template>
	<div>
		<div class="d-flex align-items-center mb-4">
			<h5
				class="pr-3 mr-2 mb-0 align-self-stretch border-right border-2 border-black font-weight-bold"
				v-if="Boolean(number)"
			>
				{{ number }}
			</h5>
			<prismic-rich-text
				:field="nextStep.text"
				class="prismic-small-copy prismic-last-p-mb-0"
			/>
		</div>

		<p class="small font-weight-bold mb-4">Your local DNO is:</p>

		<!--- Check if a DNO was Found --->
		<div class="company-card mb-4" v-if="getDnoFound">
			<!--- Company Logo --->
			<div
				v-if="getUserDno.image"
				class="d-none d-lg-block border-tran rounded px-3 mr-4 mb-2 w-full w-min-content"
			>
				<prismic-image
					class="p-2 h-20 w-32 xl:w-48 object-contain"
					:field="getUserDno.image"
				/>
			</div>
			<div v-else class="d-none d-lg-block border-tran rounded mr-4 mb-2">
				<div class="d-flex justify-content-center">
					<LoadingIcon class="p-3" />
				</div>
			</div>

			<!--- Company Details --->
			<div class="border-tran rounded py-2 px-4 mb-2 flex-auto small">
				<div class="d-flex align-items-center pb-2">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="dno-link-icon"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
						/>
					</svg>

					<prismic-link
						v-if="getUserDno.url"
						:field="getUserDno.url"
						class="text-dark d-block"
					>
						{{ getUserDno.url.url.replace("https://", "").split("/")[0] }}
					</prismic-link>
					<div v-else class="w-100 d-block">
						<p class="text-line"></p>
					</div>
				</div>
				<div class="d-flex align-items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="dno-link-icon"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="{2}"
							d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
						/>
					</svg>

					<a
						v-if="getUserDno.phone"
						:href="'tel:' + getUserDno.phone"
						class="m-0"
					>
						{{ getUserDno.phone }}
					</a>
					<div v-else class="w-100 d-block">
						<p class="text-line"></p>
					</div>
				</div>
			</div>
		</div>

		<div v-else>
			<p class="prismic-small-copy mx-5 mb-5">
				We’re having trouble finding your DNO. Please go to
				<a
					href="https://www.energynetworks.org/operating-the-networks/whos-my-network-operator"
				>
					https://www.energynetworks.org/operating-the-networks/whos-my-network-operator
				</a>
				for more information.
			</p>
		</div>
	</div>
</template>
<script>
import LoadingIcon from "@/components/icons/Loading.vue";

import { mapGetters } from "vuex";

export default {
	name: "DnoNextStep",
	props: ["number", "nextStep"],
	components: { LoadingIcon },
	computed: {
		...mapGetters({
			getUserDno: "guideUser/getUserDno",
			getDnoFound: "guideUser/getDnoFound"
		})
	}
};
</script>

<style lang="scss">
.dno-link-icon {
	color: rgba(0, 0, 0, 0.25);
	stroke: rgba(0, 0, 0, 0.25);
	stroke-width: 2px;
	height: 1.25rem;
	width: 1.25rem;
	margin-right: 0.75rem;
}
.company-card {
	margin-left: 1.95rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.border-tran {
	border: 1.5px solid;
	border-color: rgba(0, 0, 0, 0.3);
}

@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}

.animated-background {
	animation-duration: 1.25s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f6f6;
	background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
	background-size: 800px 104px;
	height: 96px;
	position: relative;
}

.text-line {
	height: 28px;
	margin: 4px 0;
	@extend .animated-background;
}
</style>
