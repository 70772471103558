import Vue from "vue";
import Vuex from "vuex";

const parsers = {
	parseJourney(journey) {
		if (journey) {
			// Parse into User Journey
			const userJourney = {
				id: journey.id,
				uid: journey.uid,

				name: journey.name,
				text: journey.user_journey_text,
				icon: journey.data.journey_icon,
				enableEmail: journey.data.journey_enable_email,

				// Parse Journey Sections:
				sections: journey.data.body.map(section =>
					parsers.parseJourneySection(section)
				),

				// Parse Journey NextSteps:
				nextSteps: journey.data.body1.map(section =>
					parsers.parseJourneyNextStep(section)
				)
			};

			// Return User Journey
			return userJourney;
		}
	},
	parseJourneySection(section) {
		const journeySection = {
			type: section.slice_type,
			heading: section.primary.section_heading,
			text: section.primary.section_text,
			popupText: section.primary.section_popup_text,
			popupToggled: section.primary.section_popup_toggle,
			nextButtonText: section.primary.next_button_text,
			modals: section.items
		};

		switch (journeySection.type) {
			case "action_section":
				journeySection["actionRequired"] =
					section.primary.section_action_required;
				break;

			default:
				break;
		}

		return journeySection;
	},
	parseJourneyNextStep(nextStep) {
		const journeyNextStep = {
			type: nextStep.slice_type,
			text: nextStep.primary.next_step_text
		};

		// switch (journeyNextStep.type) {
		//  default:
		//      break;
		// }

		return journeyNextStep;
	},
	parseDno(dno) {
		if (dno) {
			// Parse into User Dno
			const userDno = {
				id: dno.id,
				uid: dno.uid,

				name: dno.data.dno_name,
				phone: dno.data.dno_phone,
				url: dno.data.dno_url,
				image: dno.data.dno_image
			};

			// Return User Dno
			return userDno;
		}
	}
};

Vue.use(Vuex);

const guideUser = {
	namespaced: true,
	state: {
		userExists: false,

		userPostcode: "",
		userSector: "",
		userJourney: null,
		userDno: null,

		// Assume it is true and then after lookup set false
		dnoFound: true
	},
	getters: {
		// Normal State GETs
		getUserExists: state => state.userExists,
		getUserPostcode: state => state.userPostcode,
		getUserSector: state => state.userSector,
		getUserJourney: state => state.userJourney,
		getUserDno: state => state.userDno,
		getDnoFound: state => state.dnoFound,

		// Functionality GETs
		getJSON: state =>
			JSON.stringify({
				userExists: state.userExists,
				userJourney: state.userJourney,
				userDno: state.userDno
			})
	},
	mutations: {
		// Normal SET for State
		setUserExists(state, payload) {
			state.userExists = payload;
		},
		setUserPostcode(state, payload) {
			state.userPostcode = payload.toUpperCase().replace(/\s/g, "");
		},
		setUserSector(state, payload) {
			state.userSector = payload;
		},
		setUserJourney(state, payload) {
			state.userJourney = payload;
		},
		setUserDno(state, payload) {
			state.userDno = payload;
		},
		setDnoFound(state, payload) {
			state.dnoFound = payload;
		},

		// Reset State
		resetState(state) {
			state.userExists = false;
			state.userJourney = null;
			state.userDno = null;

			state.dnoFound = true;
		}
	},
	actions: {
		async setupUser(
			{ dispatch, commit },
			[userJourneyUid, userJourneyName, userPostcode, userSector]
		) {
			commit("resetState");

			commit("setUserPostcode", userPostcode);
			commit("setUserSector", userSector);
			await dispatch("setupUserJourney", [userJourneyUid, userJourneyName]);

			// Call DNO but don't await it (Dispatch will return a promise)
			commit("setUserDno", dispatch("setupUserDno"));

			commit("setUserExists", true);
		},

		async setupDomesticUser(
			{ dispatch, commit },
			[userJourneyUid, userJourneyName]
		) {
			commit("resetState");
			await dispatch("setupUserJourney", [userJourneyUid, userJourneyName]);
			commit("setUserExists", true);
		},

		async setupUserJourney(
			{ dispatch, commit, getters, rootGetters },
			[userJourneyUid, userJourneyName]
		) {
			// Get from store
			let userJourney = rootGetters["prismicGuide/getPrismicJourneyByUid"](
				userJourneyUid
			);

			// Get from API
			if (!userJourney) {
				// eslint-disable-next-line prettier/prettier
				const journeyIds = await dispatch(
					"prismicGuide/fetchPrismicJourneys",
					null,
					{ root: true }
				);

				// Check the DnoIDs
				if (journeyIds.length)
					userJourney = rootGetters["prismicGuide/getPrismicJourneyByUid"](
						userJourneyUid
					);
			}

			// Check and Setup Country
			if (userJourney) {
				userJourney["name"] = userJourneyName;
				commit("setUserJourney", parsers.parseJourney(userJourney));
			}
		},
		async setupUserDno({ dispatch, commit, getters, rootGetters }) {
			// Get DNO UID from another service using the postcode
			const userDnoUid = await Vue.prototype.$fetchDnoUidByPostcode(
				getters.getUserPostcode
			);

			// If there are no DNOs for the supplied postcode
			if (!userDnoUid) {
				commit("setDnoFound", false);
				return;
			}

			// Get from store
			let userDno = rootGetters["prismicGuide/getPrismicDnoByUid"](userDnoUid);

			// Get from API
			if (!userDno) {
				// eslint-disable-next-line prettier/prettier
				const dnoIds = await dispatch("prismicGuide/fetchPrismicDnos", null, {
					root: true
				});

				// Check the DnoIDs
				if (dnoIds.length)
					userDno = rootGetters["prismicGuide/getPrismicDnoByUid"](userDnoUid);
			}

			if (userDno) commit("setUserDno", parsers.parseDno(userDno));
		}
	}
};

export default guideUser;
