<template>
  <div id="app" class="vh-100">
    <router-view />

    <cookie-consent class="text-center px-2 light-shadow">
      <template slot="message">
        <div class="font-80 text-white">
          This website uses cookies to ensure you get the best experience.
          <a
            href="https://www.ena-eng.org/ALoMCP/assets/other/ALoMCP_Privacy.pdf"
            target="_blank"
            rel="noopener"
            class="text-white"
            style="text-decoration: underline;"
            >Read&nbsp;more</a
          >
        </div>
      </template>
      <template slot="button">
        <button class="font-80 btn btn-yellow ml-3 mt-3 mt-sm-0">
          Accept
        </button>
      </template>
    </cookie-consent>
  </div>
</template>

<script>
import CookieConsent from "vue-cookieconsent-component";

export default {
  name: "app",
  components: {
    CookieConsent
  }
};
</script>
