<template>
	<div class="card-not-sure card bg-yellow h-100">
		<div class="mb-4">
			<span class="rounded-circle badge-primary badge-icon">
				<prismic-image class="h-8 w-8" :field="cardImage" />
			</span>
		</div>
		<div class="flex-grow mb-3">
			<p class="font-weight-bold">{{ title }}</p>
		</div>
		<prismic-link
			:field="cardLink"
			class="btn-cta btn-outline-dark bg-white text-primary font-weight-bold w-100"
			>{{ buttonText }}</prismic-link
		>
	</div>
</template>

<script>
export default {
	props: {
		title: String,
		buttonText: String,
		cardLink: Object,
		cardImage: Object,
	},
};
</script>

<style lang="scss">
.badge-icon {
	padding: 0.75rem;
	img {
		margin-top: -0.35rem;
	}
}
.card-not-sure {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.card-not-sure p {
	font-size: 1.2rem;
}
</style>
