<template>
	<div>
		<Nav v-if="fields.nav" :data="fields.nav" />
		<div class="bg-sky-blue py-6">
			<div class="container">
				<div class="row">
					<h2 class="text-center w-100 heading">
						{{ fields.section1.nextStepsTitle }}
					</h2>
				</div>
				<img src="@/assets/img/next-step-lines-bg.png" class="d-none d-lg-block img-fluid" alt="bg image"/>
				<div class="row">
					<div
						class="col-12 col-md-6 col-lg-4 mb-4"
						v-for="(card, index) in fields.section1.nextStepsCards"
						:key="'section1-card-' + index"
					>
						<StepCard
							:step="index + 1"
							:title="card.card_title"
							:buttonText="card.card_link_text"
							:cardLink="card.card_link_url"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="container pt-6 pb-5">
			<div class="row">
				<h2 class="text-center w-100 heading">{{fields.section2.findOutMoreTitle}}</h2>
			</div>
			<div class="row">
				<div
					class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
					v-for="(card, index) in fields.section2.findOutMoreCards"
					:key="'section2-card-' + index"
				>
					<NextStepCardImage :title="card.card_title" :buttonText="card.card_link_text" :cardImage="card.card_image" :cardLink="card.card_link_url"/>
				</div>
			</div>
		</div>
		<Footer v-if="fields.footer" :data="fields.footer" />
	</div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import StepCard from "@/components/cards/StepCard.vue";
import NextStepCardImage from "@/components/cards/NextStepCardImage.vue";
import Footer from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

export default {
	components: {
		Nav,
		StepCard,
		NextStepCardImage,
		Footer
	},
	metaInfo() {
		return {
			title: this.page.pageTitle,
			meta: [
				{
					vmid: "description",
					name: "description",
					content: this.page.pageDesc
				},
				{
					vmid: "og:title",
					name: "og:title",
					content: this.page.pageTitle
				},
				{
					vmid: "og:description",
					name: "og:description",
					content: this.page.pageDesc
				}
			]
		};
	},
	created() {
		this.getContent();
	},
	computed: {
		...mapGetters({
			getPrismicPage: "prismicPage/getPrismicPageByName"
		})
	},
	data() {
		return {
			fields: {
				section1: {
					nextStepsTitle: null,
					next_steps_cards: []
				},
				section2: {
					findOutMoreTitle: null,
					findOutMoreCards: [],
				},
				nav: {
					button1Text: null,
					button2Text: null,
					button2Link: {}
				},
				footer: {
					topLinks: [],
					twitterLink: {},
					facebookLink: {},
					instagramLink: {},
					linkedinLink: {},
					privacyLink: {},
					termsLink: {}
				}
			},
			page: {
				pageTitle: null,
				pageDesc: null
			}
		};
	},
	methods: {
		...mapActions({
			fetchPrismicPage: "prismicPage/fetchPrismicPage"
		}),
		async getContent() {
			// Get from store
			let nextStepPage = this.getPrismicPage("next_steps_page");
			let options = this.getPrismicPage("options_page");
			// Get from API
			if (!nextStepPage)
				nextStepPage = await this.fetchPrismicPage("next_steps_page");

			if (!options) options = await this.fetchPrismicPage("options_page");

			// Check, parse, after-content
			if (options && nextStepPage) {
				this.parseOptions(options);
				this.parseDocument(nextStepPage);
			}
		},

		parseDocument(document) {
			if (document) {
				// Page
				this.page.pageTitle = document.data.page_title;
				this.page.pageDesc = document.data.page_description;

				// Section 1
				this.fields.section1.nextStepsTitle = document.data.next_steps_title;
				this.fields.section1.nextStepsCards = document.data.next_steps_cards;

				// Section 2
				this.fields.section2.findOutMoreTitle = document.data.find_out_more_title;
				this.fields.section2.findOutMoreCards = document.data.find_out_more_cards;
				
			}
		},

		parseOptions(options) {
			if (options) {
				//Footer
				this.fields.footer.topLinks = options.data.top_links;
				this.fields.footer.twitterLink = options.data.twitter_link;
				this.fields.footer.facebookLink = options.data.facebook_link;
				this.fields.footer.instagramLink = options.data.instagram_link;
				this.fields.footer.linkedinLink = options.data.linkedin_link;
				this.fields.footer.privacyLink = options.data.privacy_policy_link;
				this.fields.footer.termsLink = options.data.terms_link;

				//Nav
				this.fields.nav.button1Text = options.data.nav_button_1_text;
				this.fields.nav.button2Text = options.data.nav_button_2_text;
				this.fields.nav.button2Link = options.data.nav_button_2_link;
			}
		}
	}
};
</script>

<style>
.heading {
	font-size: 2.2rem;
	margin-bottom: 2rem;
}
.bg-sky-blue {
	background-color:#86C8EF;
}

</style>
