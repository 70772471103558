export default function(doc) {
  if (doc.isBroken) {
    return "/not-found";
  }

  // If it is a Single Custom Type with the API ID of "home"
  if (doc.type === "home_page") {
    return "/";
  }

  return "/not-found";
}
