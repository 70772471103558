<template>
	<div class="home transition-500">
		<Nav v-if="fields.nav" :data="fields.nav" />

		<!-- Hero -->
		<div class="bg-yellow tablet-bg-yellow-blue">
			<div
				class="
					container-lg min-h-screen-3/4
					d-flex flex-column flex-md-row 
					align-items-stretch text-black 
				"
			>
				<!--- Left-Hand Sun with Text --->
				<div
					class="
						col-12 col-md-6 h-auto 
						p-0 mt-2 mt-4 m-md-0 
						position-relative
						d-flex flex-column justify-content-center
						bg-yellow tablet-bg-yellow-blue
					"
				>
					<!--- Background Sun --->
					<div class="d-none d-md-block ellipse"></div>

					<!--- Text Content --->
					<div
						class="
							position-relative z-10
							p-4 p-md-5
						"
					>
						<h1 class="display-4 mb-5 font-weight-bold">
							{{ fields.hero.title }}
						</h1>
						<p class="mb-4 font-weight-500" v-if="fields.hero.description">
							{{ fields.hero.description }}
						</p>
						<router-link
							to="/guide"
							class="btn btn-outline-dark btn-shadow bg-white text-primary px-2 px-md-4 font-80 font-weight-bold"
						>
							{{ fields.hero.call_to_action_text }}
						</router-link>
					</div>
				</div>

				<!--- Right Hand Video --->
				<div
					class="
						col-md-6 h-auto 
						p-0 mt-2 mb-4 m-md-0 
						d-flex flex-column justify-content-center 
						bg-primary
					"
				>
					<div
						class="aspect-16-9 position-relative rounded-lg shadow-lg overflow-hidden"
						v-if="isHeroImage && isHeroVideo"
					>
						<!--- Video Player --->
						<div
							class="
								w-100 h-100 position-absolute 
								left-0 top-0 z-10
							"
						>
							<video
								class="hero-image"
								ref="hero-video"
								:controls="videoActive"
								:poster="fields.hero.image.url"
								:src="fields.hero.video.url"
							></video>
						</div>

						<!--- Video Overlay --->
						<button
							class="
								w-100 h-100 position-absolute 
								left-0 top-0 z-20 
								btn btn-link 
								bg-overlay text-white opacity-20
								d-flex flex-column 
								justify-content-center align-items-center
							"
							v-if="!videoActive"
							type="button"
							@click="activateVideo"
						>
							<!-- eslint-disable -->
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-24 w-24"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
									clip-rule="evenodd"
								/>
							</svg>
							<!-- eslint-enable -->
						</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Deadlines -->
		<div
			class="container py-5 py-md-6"
			v-if="fields.deadlines.deadlines.title > 0"
		>
			<div v-if="fields.deadlines.title" class="row text-center">
				<div class="col pb-3 pb-md-5">
					<h3>{{ fields.deadlines.title }}</h3>
				</div>
			</div>
			<div
				v-if="fields.deadlines.deadlines.length > 0"
				:class="
					`row text-center text-md-left row-cols-1 row-cols-md-${fields.deadlines.deadlines.length}`
				"
			>
				<div
					class="col px-3 text-center space-y-2"
					v-for="(deadline, index) in fields.deadlines.deadlines"
					:key="'deadline-' + index"
					:class="
						index !== fields.deadlines.deadlines.length - 1
							? 'mb-4 mb-md-0'
							: ''
					"
				>
					<p v-if="deadline.title" class="font-weight-500 h4">
						{{ deadline.title[0].text }}
					</p>
					<div
						:class="
							index !== fields.deadlines.deadlines.length - 1
								? 'border-dark border-bottom border-md-bottom-0 border-md-right'
								: ''
						"
						class="mt-4 pb-4"
					>
						<countdown
							:end-time="new Date(deadline.date).getTime() + 60000"
							class="font-weight-bold"
						>
							<template v-slot:process="{ timeObj }">
								<span class="h2">{{ weeks(timeObj.d) }}</span>
								<span>w </span>
								<span class="h2">{{ days(timeObj.d) }}</span>
								<span>d </span>
								<span class="h2">{{ timeObj.h }}</span>
								<span>h </span>
								<span class="h2">{{ timeObj.m }}</span>
								<span>m </span>
								<span class="h2">{{ timeObj.s }}</span>
								<span>s </span>
							</template>
							<template v-slot:finish>
								<span class="h2">0</span>
								<span>w </span>
								<span class="h2">0</span>
								<span>d </span>
								<span class="h2">0</span>
								<span>h </span>
								<span class="h2">0</span>
								<span>m </span>
								<span class="h2">0</span>
								<span>s </span>
							</template>
						</countdown>
						<div class="text-md mt-4">
							{{ deadline.date | moment("Do MMMM YYYY") }}
							<span v-if="deadline.expired" class="expired-text">
								Expired
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Section 1 -->
		<div class="bg-secondary py-md-5 position-relative">
			<div class="container">
				<div class="icon py-5 text-center">
					<!-- eslint-disable -->
					<svg
						width="340"
						height="156"
						class="w-100"
						viewBox="0 0 340 156"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g
							fill="none"
							fill-rule="evenodd"
							stroke="#363636"
							stroke-width="6"
						>
							<g stroke-linecap="round" stroke-linejoin="round">
								<path
									d="M162.464 82.19c0-4.403 3.587-7.974 8.013-7.974s8.014 3.57 8.014 7.975M186.959 54l-7.155 12.332m-19.08 0L153.57 54m50.083 28.775h-14.31m-38.158 0h-14.31M193.74 96.29h-47.48L128 125.363h84zM170.001 125.364V138M136.876 111.233h65.993M162.642 96.289l-9.158 29.075M178.669 96.289l8.014 29.075"
								/>
							</g>
							<path
								d="M62.219 49.158c.986 4.237-1.838 8.434-6.308 9.371-4.47.934-8.897-1.742-9.885-5.979-.986-4.238 1.838-8.435 6.308-9.371 4.469-.935 8.899 1.743 9.885 5.979zM52.334 43.179L44.377 9.032c-.541-2.322 1.064-4.363 3.567-4.535l7.088-.485c2.505-.171 5.034 1.58 5.624 3.892l4.117 16.17c.589 2.312-.525 5.43-2.476 6.928l-10.856 8.34"
							/>
							<path
								d="M48.006 56.16L20.787 79.765c-1.852 1.606-4.52 1.308-5.929-.661l-3.987-5.576c-1.408-1.969-1.073-4.922.746-6.561l12.712-11.465c1.819-1.64 5.224-2.285 7.568-1.434l13.05 4.742M62.03 53.224l35.179 10.54c2.393.718 3.454 3.056 2.36 5.196l-3.103 6.062c-1.095 2.14-3.96 3.341-6.368 2.669l-16.83-4.704c-2.408-.673-4.7-3.145-5.094-5.495L65.98 54.408"
							/>
							<path stroke-linecap="round" d="M53.718 59.931L54 137.117" />
							<g stroke-linecap="round" stroke-linejoin="round">
								<path
									d="M253 153c6.135 0 11.876-1.778 16.8-4.866M269.8 148.135c4.924 3.087 10.665 4.865 16.8 4.865 6.135 0 11.876-1.778 16.8-4.865"
								/>
								<path
									d="M303.4 148.135c4.924 3.087 10.665 4.865 16.8 4.865 6.135 0 11.876-1.778 16.8-4.865M253 139.07c6.135 0 11.876-1.778 16.8-4.864M269.8 134.205c4.924 3.088 10.665 4.865 16.8 4.865 6.135 0 11.876-1.777 16.8-4.865"
								/>
								<path
									d="M303.4 134.205c4.924 3.088 10.665 4.865 16.8 4.865 6.135 0 11.876-1.777 16.8-4.865M253 125.14c6.135 0 11.876-1.777 16.8-4.865M269.8 120.276c7.915 4.955 17.665 6.204 26.467 3.338M306.94 94.782s.722-12.408 16.77-25.782M294.512 103.16s-22.476 4.606-31.18-4.07M307.98 109.734c7.57 3.35 13.875 10.14 16.977 17.746M310.579 100.748c.89 3.938-1.656 7.84-5.682 8.71-4.026.868-8.014-1.62-8.904-5.557-.888-3.94 1.655-7.84 5.682-8.71 4.026-.868 8.015 1.62 8.904 5.557z"
								/>
							</g>
							<path stroke-linecap="round" d="M3 139h175" />
							<path d="M175 139h78" />
						</g>
					</svg>
					<!-- eslint-enable -->
				</div>

				<prismic-rich-text
					v-if="fields.section1.introText"
					:field="fields.section1.introText"
				/>
			</div>

			<div class="container p-0 px-md-2 yellow-card-container mt-5">
				<!-- eslint-disable -->
				<svg
					class="d-block mx-auto"
					width="36"
					height="156"
					viewBox="0 0 36 156"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g
						stroke="#363636"
						stroke-width="6"
						fill="none"
						fill-rule="evenodd"
						stroke-linejoin="round"
					>
						<path d="M18 58v98" />
						<g stroke-linecap="round">
							<path
								d="M24.845 44.5v14H11.157v-14M33 33.798c0 5.522-4.476 10-9.999 10h-10C7.479 43.797 3 39.32 3 33.797v-15h30v15zM8.001 18.799v-15M28.001 18.799v-15"
							/>
						</g>
					</g>
				</svg>
				<!-- eslint-enable -->

				<div class="card bg-yellow text-sm-center px-md-5 warning-icon">
					<div class="card-body">
						<div class="icon py-3 py-md-5">
							<!-- eslint-disable -->
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="90"
								height="90"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<circle cx="12" cy="12" r="10" />
								<line x1="12" y1="8" x2="12" y2="12" />
								<line x1="12" y1="16" x2="12.01" y2="16" />
							</svg>
							<!-- eslint-enable -->
						</div>
						<h3>{{ fields.section1.largeCardTitle }}</h3>
						<prismic-rich-text
							v-if="fields.section1.largeCardText"
							:field="fields.section1.largeCardText"
							class="py-5"
						/>
					</div>
				</div>
			</div>

			<!-- eslint-disable -->
			<svg
				class="d-none d-lg-block middle-line"
				width="396"
				height="225"
				viewBox="0 0 396 225"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M393 0v66c0 11.046-8.954 20-20 20H23c-11.046 0-20 8.954-20 20v119h0"
					stroke="#363636"
					stroke-width="6"
					fill="none"
					fill-rule="evenodd"
					stroke-linejoin="round"
				/>
			</svg>
			<!-- eslint-enable -->

			<div class="container position-relative">
				<!-- eslint-disable -->
				<svg
					class="d-none d-lg-block behind-line"
					width="500"
					height="8"
					viewBox="0 0 421 6"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M421 3H0"
						stroke="#363636"
						stroke-width="5"
						fill="none"
						fill-rule="evenodd"
						stroke-linejoin="round"
					/>
				</svg>
				<!-- eslint-enable -->
				<div class="row row-cols-1 row-cols-md-3 pt-5 pt-lg-0 pb-5 pb-md-0">
					<div
						class="col mb-4 mb-md-0"
						v-for="(card, index) in fields.section1.cards"
						:key="'section1-card-' + index"
					>
						<div class="card h-100 position-relative">
							<div class="position-absolute" style="left: 15px; top: 5px;">
								<span class="badge rounded-circle px-2 badge-primary">
									{{ index + 1 }}
								</span>
							</div>
							<div class="card-body text-md-center">
								<div class="icon py-3 py-md-5">
									<!-- eslint-disable -->
									<svg
										id="magnify"
										width="90"
										height="90"
										viewBox="0 0 90 90"
										xmlns="http://www.w3.org/2000/svg"
										v-if="index === 0"
									>
										<path
											d="M87 87L59 59m9.333-23.333c0 18.041-14.625 32.666-32.666 32.666C17.625 68.333 3 53.708 3 35.667 3 17.625 17.625 3 35.667 3c18.041 0 32.666 14.625 32.666 32.667z"
											stroke="#2F6EB4"
											stroke-width="6"
											fill="none"
											fill-rule="evenodd"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<svg
										id="application"
										width="90"
										height="90"
										viewBox="0 0 90 90"
										xmlns="http://www.w3.org/2000/svg"
										v-if="index === 1"
									>
										<path
											d="M30.667 12.333h-9.334A9.333 9.333 0 0012 21.667v56A9.333 9.333 0 0021.333 87H68a9.333 9.333 0 009.333-9.333v-56A9.333 9.333 0 0068 12.333h-9.333m-28 0A9.333 9.333 0 0040 21.667h9.333a9.333 9.333 0 009.334-9.334m-28 0A9.333 9.333 0 0140 3h9.333a9.333 9.333 0 019.334 9.333m-28 42L40 63.667 58.667 45"
											stroke="#2F6EB4"
											stroke-width="6"
											fill="none"
											fill-rule="evenodd"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>

									<svg
										v-if="index === 2"
										xmlns="http://www.w3.org/2000/svg"
										width="90"
										height="90"
										viewBox="0 0 64 77"
										fill="none"
									>
										<path
											d="M61.257 20.1068V72.5742C61.257 73.8373 60.2154 74.8789 58.9524 74.8789H5.05762C3.79461 74.8789 2.75293 73.8373 2.75293 72.5742V4.38794C2.75293 3.12493 3.79461 2.08325 5.05762 2.08325H44.212L61.257 20.1068Z"
											stroke="#2F6EB4"
											stroke-width="6"
											stroke-miterlimit="10"
										/>
										<path
											d="M32.0049 29.0646V48.6833"
											stroke="#2F6EB4"
											stroke-width="6"
											stroke-miterlimit="10"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M22.1973 38.4846H41.8123"
											stroke="#2F6EB4"
											stroke-width="6"
											stroke-miterlimit="10"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>

									<!-- eslint-enable -->
								</div>
								<p class="font-weight-bold">{{ card.title }}</p>
								<prismic-rich-text class="font-80" :field="card.content" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- eslint-disable -->
			<svg
				class="d-none d-lg-block bottom-line pb-2"
				width="411"
				height="120"
				xmlns="http://www.w3.org/2000/svg"
				xml:space="preserve"
				viewBox="0 0 505 147"
			>
				<path
					fill="none"
					stroke="#363636"
					stroke-linejoin="round"
					stroke-width="7"
					d="M11.155 200.799v-14h13.688v14M3 211.5c0-5.521 4.476-9.999 9.999-9.999h10c5.522 0 10.001 4.478 10.001 10v15H3v-15zm24.999 15v15m-20-15v15"
					transform="matrix(0 1 -1.13 0 273.2995 55.89)"
				/>
				<path
					fill="none"
					stroke="#363636"
					stroke-linejoin="round"
					stroke-width="7"
					d="M408 0v51.476c0 11.045-8.954 20-20 20H38"
					transform="matrix(1.18 0 0 1.04 20.09 -1.31)"
				/>
			</svg>
			<!-- eslint-enable -->
		</div>

		<!-- Section 3 -->
		<div class="bg-primary mt-32 clip-top" id="apply">
			<div class="container pb-3 pb-md-0 pt-5 pt-md-6">
				<div class="row text-white text-md-center justify-content-center">
					<div class="col-md-6">
						<h2 class="mb-5">{{ fields.section3.title }}</h2>
						<p class="mb-5 font-80">{{ fields.section3.text }}</p>
					</div>
				</div>

				<div class="row row-cols-1 row-cols-md-3 mt-md-5">
					<div
						class="col mb-3 mb-md-0"
						v-for="(card, index) in fields.section3.cards"
						:key="'section3-card-' + index"
					>
						<div class="card bg-yellow h-100 font-80">
							<div class="card-body text-md-center">
								<div class="icon py-3 py-md-5">
									<!-- eslint-disable -->

									<svg
										v-if="index === 0"
										xmlns="http://www.w3.org/2000/svg"
										width="99"
										height="103"
										viewBox="0 0 99 103"
										fill="none"
									>
										<path
											d="M87.3378 14.3045C76.4415 12.1134 65.9577 8.22101 56.2725 2.77004C54.2184 1.56664 51.8814 0.932541 49.5008 0.932541C47.1203 0.932541 44.7832 1.56664 42.7292 2.77004C33.0429 8.22101 22.5593 12.1134 11.6639 14.3045C8.63692 14.9419 5.91931 16.5964 3.96274 18.9925C2.00547 21.3886 0.927606 24.382 0.907866 27.4755V36.0412C0.847983 47.898 3.83149 59.5717 9.57205 69.9478C15.3134 80.3222 23.6206 89.0504 33.6989 95.2969L42.3869 100.687C44.5173 102.019 46.9789 102.726 49.4916 102.726C52.0042 102.726 54.4661 102.019 56.5963 100.687L65.2794 95.2969C75.3627 89.0536 83.6741 80.3262 89.421 69.952C95.1664 59.5767 98.1532 47.902 98.0959 36.0412V27.4755C98.0762 24.3822 96.9983 21.3889 95.041 18.9925C93.0846 16.5964 90.3668 14.9419 87.3399 14.3045H87.3378ZM90.7675 36.0416C90.7413 46.6336 88.0358 57.0479 82.904 66.3131C77.7721 75.5793 70.3811 83.3976 61.4168 89.0393L52.7388 94.4296C51.7692 95.0374 50.647 95.3598 49.5027 95.3598C48.3576 95.3598 47.2362 95.0374 46.2666 94.4296L37.5835 89.0393C28.6241 83.3939 21.2371 75.5747 16.111 66.3089C10.9832 57.0435 8.28202 46.6313 8.25745 36.0416V27.4759C8.27139 26.0699 8.76441 24.7106 9.65525 23.6228C10.5461 22.5343 11.7815 21.7837 13.1581 21.4932C24.8147 19.1454 36.0291 14.9734 46.3843 9.13031C47.3243 8.58152 48.3924 8.29195 49.4809 8.29195C50.5694 8.29195 51.6382 8.58152 52.5776 9.13031C62.9339 14.9734 74.1467 19.1454 85.8038 21.4932C87.1803 21.7836 88.4157 22.5342 89.3066 23.6228C90.1982 24.7106 90.6913 26.0698 90.7044 27.4759L90.7675 36.0416Z"
											fill="#363636"
										/>
										<path
											d="M61.995 39.0593L44.9432 51.8479L37.3971 44.3026C36.4586 43.4281 35.1331 43.1066 33.8985 43.4527C32.6647 43.7989 31.7 44.7636 31.3531 45.9981C31.0069 47.2327 31.3293 48.5583 32.2029 49.4967L42.0032 59.297V59.2962C42.6275 59.9229 43.4576 60.3019 44.3403 60.3651C45.2221 60.4282 46.0974 60.1706 46.8053 59.6391L66.405 44.9391C67.455 44.1516 68.0054 42.8637 67.8487 41.5593C67.6912 40.2559 66.8512 39.1353 65.6437 38.6193C64.4362 38.1042 63.045 38.2715 61.995 39.059L61.995 39.0593Z"
											fill="#363636"
										/>
									</svg>
									<svg
										id="globe"
										width="90"
										height="90"
										viewBox="0 0 90 90"
										xmlns="http://www.w3.org/2000/svg"
										v-if="index === 1"
									>
										<path
											d="M3.256 40.333h9.077a9.333 9.333 0 019.334 9.334v4.666A9.333 9.333 0 0031 63.667 9.333 9.333 0 0140.333 73v13.744m-14-79.378v7.3c0 6.444 5.224 11.667 11.667 11.667h2.333a9.333 9.333 0 019.334 9.334A9.333 9.333 0 0059 45a9.333 9.333 0 009.333-9.333 9.333 9.333 0 019.334-9.334h4.967M59 84.61V73a9.333 9.333 0 019.333-9.333h14.301M87 45c0 23.196-18.804 42-42 42S3 68.196 3 45 21.804 3 45 3s42 18.804 42 42z"
											stroke="#363636"
											stroke-width="6"
											fill="none"
											fill-rule="evenodd"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<svg
										id="lightening"
										width="90"
										height="90"
										viewBox="0 0 90 90"
										xmlns="http://www.w3.org/2000/svg"
										v-if="index === 2"
									>
										<path
											stroke="#363636"
											stroke-width="6"
											d="M50.25 35.667V3L3 54.333h36.75V87L87 35.667z"
											fill="none"
											fill-rule="evenodd"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<!-- eslint-enable -->
								</div>
								<p class="font-weight-bold">{{ card.title }}</p>
								<p>{{ card.text }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Section 4 -->
		<div class="container py-5 py-md-6" v-if="fields.section4.showStats">
			<div class="row text-center">
				<div class="col pb-3 pb-md-5">
					<h3>{{ fields.section4.title }}</h3>
				</div>
			</div>
			<div class="row text-center text-md-left row-cols-1 row-cols-md-4">
				<div
					class="col border-md-left border-dark px-3"
					:class="
						index === fields.section4.stats.length - 1 ? 'border-md-right' : ''
					"
					v-for="(stat, index) in fields.section4.stats"
					:key="'stat-' + index"
				>
					<h4 class="font-weight-bold h3">{{ stat.number }}</h4>
					<p class="font-70">{{ stat.text }}</p>
				</div>
			</div>
		</div>

		<!-- Section 5 -->
		<div class="bg-primary">
			<div
				class="section-5 py-5"
				:style="{
					backgroundImage: `url(${fields.section5.image &&
						fields.section5.image.url})`
				}"
			>
				<div class="container py-md-5">
					<div
						class="row text-white py-5 align-items-center justify-content-center h-100"
					>
						<div class="col-lg-8">
							<h3 class="mb-4 mb-md-5">{{ fields.section5.title }}</h3>
							<prismic-rich-text
								v-if="fields.section5.text"
								:field="fields.section5.text"
							/>
						</div>
						<div class="col-12 col-lg-4"></div>
					</div>
				</div>
			</div>
		</div>

		<!-- FAQ's -->
		<div class="container faqs" id="faqs">
			<div class="row text-center">
				<div class="col py-4 py-md-5">
					<h3 class="text-primary">{{ fields.faqs.title }}</h3>
				</div>
			</div>
			<div class="row">
				<div
					class="col-12 mb-3"
					v-for="(item, index) in fields.faqs.content"
					:key="'faq-' + index"
				>
					<div
						class="card h-100 border border-dark"
						v-b-toggle="'faq-' + index"
					>
						<div class="card-body px-md-5">
							<div class="d-flex justify-content-between">
								<p class="font-weight-bold m-0">{{ item.title }}</p>
								<div class="icon">
									<!-- eslint-disable -->
									<svg
										class="faq-arrow"
										width="34"
										height="34"
										viewBox="0 0 34 34"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M22 19l-5 5m0 0l-5-5m5 5V11m0 21C8.716 32 2 25.284 2 17 2 8.716 8.716 2 17 2c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15z"
											stroke="#363636"
											stroke-width="3"
											fill="none"
											fill-rule="evenodd"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<!-- eslint-enable -->
								</div>
							</div>

							<b-collapse
								:visible="index === 0"
								:id="'faq-' + index"
								class="mt-3"
							>
								<prismic-rich-text v-if="item.text" :field="item.text" />
							</b-collapse>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Members -->
		<div class="container my-md-3 py-5">
			<div class="row text-center">
				<div class="col">
					<h3 class="text-primary">{{ fields.members.title }}</h3>
					<hr class="mt-5" />
				</div>
			</div>
			<div class="row row-cols-3 row-cols-md-5 align-items-center">
				<div
					class="col py-4"
					v-for="(item, index) in fields.members.logos"
					:key="'member-logo-' + index"
				>
					<prismic-image
						class="w-100"
						v-if="item.logo"
						:field="item.logo"
						:alt="item.logo.alt"
					/>
				</div>
			</div>
			<hr />
		</div>

		<Footer v-if="fields.footer" :data="fields.footer" />
	</div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

export default {
	name: "home",
	components: {
		Nav,
		Footer
	},
	metaInfo() {
		return {
			title: this.page.pageTitle,
			meta: [
				{
					vmid: "description",
					name: "description",
					content: this.page.pageDesc
				},
				{
					vmid: "og:title",
					name: "og:title",
					content: this.page.pageTitle
				},
				{
					vmid: "og:description",
					name: "og:description",
					content: this.page.pageDesc
				},
				{
					vmid: "og:image",
					name: "og:image",
					content: this.fields.hero.image && this.fields.hero.image.url
				},
				{
					vmid: "og:url",
					name: "og:url",
					content: window.location.href.replace(location.hash, "")
				},
				{
					vmid: "twitter:card",
					name: "twitter:card",
					content: "summary_large_image"
				},
				{
					vmid: "twitter:title",
					name: "twitter:title",
					content: this.page.pageTitle
				},
				{
					vmid: "twitter:description",
					name: "twitter:description",
					content: this.page.pageDesc
				},
				{
					vmid: "twitter:image",
					name: "twitter:image",
					content: this.fields.hero.image && this.fields.hero.image.url
				}
			]
		};
	},
	created() {
		this.getContent();
	},
	data() {
		return {
			fields: {
				hero: {
					image: null,
					title: null
				},
				deadlines: {
					title: null,
					deadlines: []
				},
				section1: {
					introText: null,
					largeCardTitle: null,
					largeCardText: null,
					cards: []
				},
				section2: {
					heading: null,
					testimonials: []
				},
				section3: {
					title: null,
					text: null,
					cards: null
				},
				section4: {
					title: null,
					stats: []
				},
				section5: {
					image: null,
					title: null,
					text: null
				},
				faqs: {
					title: null,
					content: []
				},
				members: {
					title: null,
					logos: []
				},
				footer: {
					topLinks: [],
					twitterLink: {},
					facebookLink: {},
					instagramLink: {},
					linkedinLink: {},
					privacyLink: {},
					termsLink: {}
				},
				nav: {
					button1Text: null,
					button2Text: null,
					button2Link: {}
				}
			},
			page: {
				pageTitle: null,
				pageDesc: null
			},
			videoActive: false
		};
	},
	computed: {
		...mapGetters({
			getPrismicPage: "prismicPage/getPrismicPageByName"
		}),
		isHeroImage() {
			return this.fields.hero.image && this.fields.hero.image.url;
		},
		isHeroVideo() {
			return this.fields.hero.video && this.fields.hero.video.url;
		}
	},
	methods: {
		...mapActions({
			fetchPrismicPage: "prismicPage/fetchPrismicPage"
		}),

		activateVideo() {
			this.videoActive = true;
			if (this.$refs["hero-video"]) this.$refs["hero-video"].play();
		},

		weeks(days) {
			var weeks = parseInt(days / 7);
			days = days - weeks * 7;
			return weeks;
		},

		days(days) {
			var weeks = parseInt(days / 7);
			days = days - weeks * 7;
			return days;
		},

		async getContent() {
			// Get from store
			let page = this.getPrismicPage("homepage");
			let options = this.getPrismicPage("options_page");

			// Get from API
			if (!page) page = await this.fetchPrismicPage("homepage");
			if (!options) options = await this.fetchPrismicPage("options_page");

			// Check, parse, after-content
			if (page && options) {
				this.parseDocument(page);
				this.parseOptions(options);
				this.afterContent();
			}
		},
		afterContent() {
			this.doFadeIn();
		},
		doFadeIn() {
			document.querySelector(".home").classList.remove("opacity-0");
		},
		parseDocument(document) {
			if (document) {
				// Page
				this.page.pageTitle = document.data.page_title;
				this.page.pageDesc = document.data.page_description;

				// Hero
				this.fields.hero.image = document.data.hero_image;
				this.fields.hero.video = document.data.hero_video;
				this.fields.hero.title = document.data.hero_title;
				this.fields.hero.description = document.data.hero_description;
				this.fields.hero.call_to_action_text =
					document.data.call_to_action_text;

				// Deadlines
				this.fields.deadlines.title = document.data.section_title_4;
				this.fields.deadlines.deadlines = document.data.deadlines;
				this.fields.deadlines.expired = document.data.expired;

				// Section 1
				this.fields.section1.introText = document.data.intro_text;
				this.fields.section1.largeCardTitle = document.data.large_card_title;
				this.fields.section1.largeCardText = document.data.large_card_text;
				this.fields.section1.cards = document.data.cards;

				// Section 2
				this.fields.section2.heading = document.data.section_heading;
				this.fields.section2.testimonials = document.data.testimonials;

				// Section 3
				this.fields.section3.title = document.data.section_title;
				this.fields.section3.text = document.data.section_text;
				this.fields.section3.cards = document.data.cards_2;

				// Section 4
				this.fields.section4.title = document.data.section_title_2;
				this.fields.section4.stats = document.data.stats;
				this.fields.section4.showStats = document.data.show_stats;
				// Section 5
				this.fields.section5.image = document.data.right_image;
				this.fields.section5.title = document.data.section_title_3;
				this.fields.section5.text = document.data.section_text_2;

				// FAQ's
				this.fields.faqs.title = document.data.faq_title;
				this.fields.faqs.content = document.data.faqs;

				//Our members
				this.fields.members.title = document.data.members_title;
				this.fields.members.logos = document.data.members_logos;
			}
		},
		parseOptions(options) {
			if (options) {
				//Footer
				this.fields.footer.topLinks = options.data.top_links;
				this.fields.footer.twitterLink = options.data.twitter_link;
				this.fields.footer.facebookLink = options.data.facebook_link;
				this.fields.footer.instagramLink = options.data.instagram_link;
				this.fields.footer.linkedinLink = options.data.linkedin_link;
				this.fields.footer.privacyLink = options.data.privacy_policy_link;
				this.fields.footer.termsLink = options.data.terms_link;

				//Nav
				this.fields.nav.button1Text = options.data.nav_button_1_text;
				this.fields.nav.button2Text = options.data.nav_button_2_text;
				this.fields.nav.button2Link = options.data.nav_button_2_link;
			}
		}
	}
};
</script>
<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

#faqs:before,
#apply:before {
	content: "";
	display: block;
	height: 100px;
	margin: -100px 0 0;
}

.hero {
	background-position: -100%;
	background-repeat: no-repeat;
	background-size: 118%;

	@include media-breakpoint-up(md) {
		clip-path: ellipse(80% 100% at 50% 0);
		height: 65vh;

		&:before {
			content: "";
			display: inline-block;
			width: 400px;
			height: 400px;
			border-radius: 50%;
			background: $yellow;
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
}

.yellow-card-container {
	.card {
		border-radius: 0;
		@include media-breakpoint-up(sm) {
			border-radius: $card-border-radius;
		}
	}
}

.card {
	transition: color 0.3s ease-in, border-color 0.3s ease-in;

	.faq-arrow {
		transition: stroke 0.3s ease-in, transform 0.3s ease-in;
	}

	&.not-collapsed {
		color: $primary !important;
		border-color: $primary !important;
		path {
			stroke: $primary !important;
		}

		.faq-arrow {
			transform: rotate(180deg);
		}
	}
}

.clip-top {
	@include media-breakpoint-up(md) {
		clip-path: ellipse(100% 100% at 50% 100%);
		background: $primary;
	}
}

.section-5 {
	background-position: right;
	background-repeat: no-repeat;
	background-size: 50%;

	@include media-breakpoint-down(md) {
		background-image: none !important;
	}
}

.middle-line {
	margin-left: calc(50% - 396px);
}

.bottom-line {
	margin-left: calc(75% - 411px);

	@media screen and (min-width: 1920px) {
		margin-left: calc(70% - 411px);
	}

	@media screen and (min-width: 2410px) {
		margin-left: calc(65% - 411px);
	}

	@media screen and (min-width: 3400px) {
		margin-left: calc(60% - 411px);
	}
}

.behind-line {
	position: absolute;
	bottom: 0;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
}

.secondary-overflow {
	@include media-breakpoint-up(lg) {
		background: linear-gradient(to bottom, $secondary 92.5%, white 50%);
	}
	z-index: 10;
}

.faqs {
	.card {
		border-width: 3px !important;
		border-radius: 10px;
	}
}
</style>
