/* eslint-disable no-console */
import Vue from "vue";
import { EventEmitter } from "events";

class DripApiService extends EventEmitter {
	constructor() {
		super();
	}

	/**
	 * Method to submit a get request to the netlify serverless function for drip api subscribers
	 */
	async createUpdateSubscriber(subscriber) {
		const endpoint = `/.netlify/functions/drip-api-subscribers`;

		// prettier-ignore
		return await Vue.axios
			.post(endpoint, subscriber)
			.catch(error => {
				console.error(error);
				return {};
			});
	}
}

const dripApiService = new DripApiService();

dripApiService.setMaxListeners(5);

export default dripApiService;
