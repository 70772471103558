import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const prismicPage = {
	namespaced: true,
	state: {
		prismicPages: new Map()
	},
	getters: {
		getPrismicPages: state => state.prismicPages.entries(),
		getPrismicPageByName: state => prismicPageName =>
			state.prismicPages.get(prismicPageName),

		// Functionality GETs
		getJSON: state =>
			JSON.stringify({
				prismicPages: Array.from(state.prismicPages.entries())
			})
	},
	mutations: {
		setPrismicPage(state, payload) {
			state.prismicPages.set(payload.name, payload.document);
		}
	},
	actions: {
		async fetchPrismicPage({ commit }, prismicPageName, prismicPromise = null) {
			// Default Prismic Promise
			if (!prismicPromise)
				prismicPromise = Vue.prototype.$prismic.client.query(
					Vue.prototype.$prismic.Predicates.at("document.type", prismicPageName)
					// { lang: Vue.prototype.$lang }
					// { lang: "es-es" }
				);

			// Default Document Return
			let document = null;

			// Wait for Promise
			const data = await prismicPromise;

			// Get from Normal Data
			if (data.results !== undefined && data.results.length > 0)
				document = data.results[0];
			// Fetch from Single
			else
				document = await Vue.prototype.$prismic.client.getSingle(
					prismicPageName
				);

			// Check and Store new fetch of the Page
			if (document)
				commit("setPrismicPage", {
					name: prismicPageName,
					document: document
				});

			// Return Null or Document Data
			return document;
		}
	}
};

export default prismicPage;
