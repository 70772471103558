<template>
	<nav
		id="nav"
		class="navbar bg-white w-100 navbar-expand-md navbar-light transition-250 sticky-top shadow px-md-5"
	>
		<a href="/#" class="navbar-brand mx-auto ml-md-0 mr-md-auto">
			<Logo />
		</a>
		<div
			id="navbar"
			class="py-lg-3 text-center justify-content-center justify-content-md-end w-100 w-sm-auto"
		>
			<div
				class="navbar-nav align-items-center justify-content-center my-3 my-md-0 flex-row"
			>
				<a
					href="/"
					class="btn btn-rounded btn-primary py-2 md-py-3 px-3 px-md-4 font-80 font-weight-bold d-flex align-items-center"
				>
					<div class="mr-2" style="width: 1.45rem">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="w-100"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M15 19l-7-7 7-7"
							/>
						</svg>
					</div>

					<span>
						Back to site
					</span>
				</a>
			</div>
		</div>
	</nav>
</template>

<script>
import Logo from "@/components/icons/Logo.vue";

export default {
	name: "Nav",
	components: {
		Logo
	}
};
</script>

<style></style>
