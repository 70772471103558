<template>
	<nav aria-label="Progress" class="pt-5">
		<ol role="list" class="d-flex align-items-center">
			<li class="relative step-bar">
				<div
					class="absolute inset-0 d-flex align-items-center"
					aria-hidden="true"
				>
					<div class="step-bar-height w-100 bg-yellow" />
				</div>
				<div
					:class="[
						stepIndex > 0 ? 'bg-yellow' : 'bg-grey',
						' relative step-pill d-flex align-items-center justify-content-center  rounded-full',
					]"
				>
					<span class="sr-only">{{ name }}</span>
					<span class="w-5 h-5 text-dark" v-if="stepIndex > 0"> 1</span>
				</div>
			</li>
			<li class="relative step-bar">
				<div
					class="absolute inset-0 d-flex align-items-center"
					aria-hidden="true"
				>
					<div
						:class="[
							stepIndex > 1 ? 'bg-yellow' : 'bg-white',
							' step-bar-height w-100',
						]"
					/>
				</div>
				<div
					:class="[
						stepIndex > 1 ? 'bg-yellow' : 'bg-white',
						' relative step-pill d-flex align-items-center justify-content-center  rounded-full',
					]"
				>
					<span class="sr-only">{{ name }}</span>
					<span class="w-5 h-5 text-dark" v-if="stepIndex > 1"> 2</span>
				</div>
			</li>

			<li class="relative">
				<div
					class="absolute inset-0 d-flex align-items-center"
					aria-hidden="true"
				>
					<div
						:class="[
							stepIndex == 3 ? 'bg-yellow' : 'bg-white',
							' step-bar-height w-100',
						]"
					/>
				</div>
				<div
					:class="[
						stepIndex == 3 ? 'bg-yellow' : 'bg-white',
						' relative step-pill d-flex align-items-center justify-content-center  rounded-full',
					]"
				>
					<span class="sr-only">{{ name }}</span>
					<span class="w-5 h-5 text-dark" v-if="stepIndex == 3"> 3</span>
				</div>
			</li>
		</ol>
	</nav>
</template>

<script>
export default {
	props: ["stepIndex", "name"],
};
</script>

<style>
.step-bar {
	padding-right: 5rem;
}

.step-bar-height {
	height: 0.425rem;
}

.step-pill{
    height: 2.5rem;
    width: 2.5rem;
} 
</style>