<template>
	<div>
		<button
			class="d-flex btn-reset align-items-center p-0"
			@click="$bvModal.show('relay-modal')"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-6 w-6 modal-trigger-icon"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>
			<span class="ml-2 modal-trigger-text">{{ btnText }} </span>
		</button>

		<b-container>
			<b-modal
				id="relay-modal"
				hide-footer
				size="lg"
				:header-class="modalHeaderStyle"
				:body-class="modalBodyStyles"
			>
				<template #modal-header>
					<h5 class="modal-subheading m-0">
						{{ modals[startIndex].popup_title }}
					</h5>
					<button
						class="btn-reset modal-btn-close"
						@click="$bvModal.hide('relay-modal')"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="w-6"
							fill="none"
							viewBox="0 0 20 20"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</template>
				<b-container fluid>
					<button
						@click="prev"
						class="carousel-prev btn-reset"
						v-if="totalModals > 1"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-16 w-16"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M15 19l-7-7 7-7"
							/>
						</svg>
					</button>
					<b-carousel id="model-carousel" :interval="0" ref="modalCarousel">
						<b-carousel-slide
							class="col mb-4 mb-md-0"
							v-for="(modal, index) in modals"
							:key="'carousel-slide-' + index"
						>
							<template #img>
								<prismic-image
									class="img-fluid mb-4 modal-image"
									v-if="modal.popup_image"
									:field="modal.popup_image"
									:alt="modal.popup_image.alt"
								/>
							</template>
						</b-carousel-slide>
					</b-carousel>
					<button
						@click="next"
						class="carousel-next btn-reset"
						v-if="totalModals > 1"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-16 w-16"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M9 5l7 7-7 7"
							/>
						</svg>
					</button>
					<h3 class="modal-subheading">{{ modals[startIndex].subheading }}</h3>

					<prismic-rich-text
						class="prismic-small-copy prismic-line-breaks mt-4 modal-text"
						:field="modals[startIndex].popup_text"
					/>
				</b-container>
			</b-modal>
		</b-container>
	</div>
</template>

<script>
export default {
	name: "modal",
	props: {
		btnText: String,
		modals: Array,
		totalModals: Number
	},
	data() {
		return {
			startIndex: 0,
			displayArrows: true,
			modalHeaderStyle: [
				"border-0",
				"d-flex",
				"align-items-center",
				"modal-header-spacing"
			],
			modalBodyStyles: ["py-0"]
		};
	},
	methods: {
		prev() {
			this.$refs.modalCarousel.prev();
			// Wrap around the carousels prevent negative index
			if (this.startIndex > 0) {
				this.startIndex--;
			} else {
				this.startIndex = this.modals.length - 1;
			}
		},
		next() {
			this.$refs.modalCarousel.next();
			// Wrap around the carousels prevent accessing array out of bounds
			if (this.startIndex < this.modals.length - 1) {
				this.startIndex++;
			} else {
				this.startIndex = 0;
			}
		}
	}
};
</script>

<style>
#relay-modal .modal-header-spacing {
	padding: 1rem 2rem;
}
.modal-text {
	font-size: 0.9rem;
	color: #808080;
	margin-bottom: 1.5rem;
}
.modal-image {
	object-fit: contain;
	min-height: 25rem;
	width: 100%;
}
.modal-trigger-icon {
	width: 2rem;
	font-weight: bolder;
	color: #808080;
}
.modal-trigger-text {
	font-weight: bolder;
	color: #808080;
	font-size: 0.95rem;
	border-bottom: 1px solid #808080;
}

.modal-header {
	padding: 0.6rem 2rem;
}
.modal-btn-close {
	color: gray;
	padding: 0;
}
.modal-subheading {
	font-size: 1.25rem;
}
</style>
