<template>
	<div class="404">
		<!--- Main and Background --->
		<div class="guide-background"></div>
		<main class="d-flex">
			<div class="container my-32">
				<div class="row">
					<div class="col-lg-8 mx-auto text-white text-center">
						<h1 class="display-3 font-weight-semibold">404</h1>
						<p class="w-lg-75 mx-auto mt-4 lead">
							Oops! We can't seem to find the page you requested.
						</p>
						<b-button
							variant="secondary"
							class="font-weight-bold"
							size="lg"
							:to="{ name: 'home' }"
						>
							Home
						</b-button>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "not-found"
};
</script>

<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.\34 04 {
	background-color: $secondary;
	min-height: 100vh;
}
</style>
