<template>
	<div class="guide transition-500">
		<GuideNav />
		<Loading :is-loading="loading.route || loading.page" />

		<!-- steps 1 -->
		<!--- Main and Background --->
		<div class="guide-background"></div>

		<main class="container flex-auto">
			<div v-if="!loading.route && !loading.page" class="container relative pb-4 d-flex justify-content-center">
				<Pagination stepIndex="1" name="beginning" />
			</div>
			<!--- Card Wrapper --->
			<div
				v-if="!loading.route && !loading.page"
				class="pt-0 guide-card w-100 w-md-75 w-2xl-60"
			>
				<!--- Content --->
				<div class="mb-4 d-flex flex-column align-items-center">
					<div class="pb-5 semicircle-yellow">
						<prismic-image
							class="object-contain w-48 h-32"
							:field="fields.guide.image"
						/>
					</div>
					<prismic-rich-text
						class="prismic-small-copy w-100"
						:field="fields.guide.text"
					/>
				</div>

				<!--- Form Models --->
				<div class="position-relative">
					<!--- Journey Dropdown --->
					<label
						:for="$refs['formModelJourney']"
						class="mb-0 d-block small font-weight-bold"
					>
						What type of generator do you have?
					</label>
					<select
						ref="formModelJourney"
						:class="{ 'border-danger': formModels.journey.highlightInvalid }"
						v-model="formModels.journey.model"
						class="p-2 mb-5 border-2 rounded w-100 small"
					>
						<option value="">Choose a category</option>
						<option
							v-for="(journey, index) in fields.journeys"
							:key="`journey-${index}-${journey.uid}`"
							:value="`journey-${index}-${journey.uid}`"
							:data-journey-uid="journey.uid"
						>
							{{ journey.text }}
						</option>
					</select>

					<!--- Postcode Input --->
					<div v-if="!isjourneyDomestic()">
						<label
							:for="$refs['formModelPostcode']"
							class="mb-0 d-block small font-weight-bold"
						>
							Where is your generator?
						</label>

						<input
							ref="formModelPostcode"
							:class="{ 'border-danger': formModels.postcode.highlightInvalid }"
							type="text"
							v-model="formModels.postcode.model"
							@blur="
								checkFormModels();
								highlightInvalidFormModel();
							"
							@keyup.enter="submitJourney()"
							placeholder="Enter your Postcode"
							class="p-2 mb-2 border-2 rounded  text-uppercase placeholder:text-normal-case small remove-focus"
						/>
						<div v-if="formModels.postcode.errorMsg" style="font-size: 1.25rem">
							<p class="mb-0 small text-danger">
								{{ formModels.postcode.errorMsg }}
							</p>
						</div>

						<!--- Sectors Dropdown --->
						<div v-if="!isjourneyDomestic()">
							<label
								:for="$refs['formModelSector']"
								class="mt-4 mb-0 d-block small font-weight-bold"
							>
								Which sector does your generator operate in?
							</label>
							<select
								ref="formModelSector"
								:class="{ 'border-danger': formModels.sector.highlightInvalid }"
								v-model="formModels.sector.model"
								class="p-2 mb-5 border-2 rounded w-100 small"
							>
								<option value="">Choose a sector</option>
								<option
									v-for="(sector, index) in fields.sectors"
									:key="`sector-${index}`"
									:value="sector"
								>
									{{ sector }}
								</option>
							</select>
						</div>
					</div>

					<!--- Buttons --->
					<div class="pb-5 position-relative">
						<button
							class="btn btn-rounded btn-primary guide-button"
							@click="submitJourney"
						>
							<small>See results</small>
						</button>
					</div>
				</div>
			</div>
		</main>

		<Footer v-if="options.footer" :data="options.footer" />
	</div>
</template>

<script>
import GuideNav from "@/components/guide/nav/GuideNav.vue";
import Loading from "@/components/Loading.vue";
import Footer from "@/components/Footer.vue";

import Pagination from "@/components/Pagination.vue";

import { mapGetters, mapActions } from "vuex";

export default {
	name: "guide",
	components: {
		GuideNav,
		Pagination,
		Loading,
		Footer
	},
	metaInfo() {
		return {
			title: this.page.pageTitle,
			meta: [
				{
					vmid: "description",
					name: "description",
					content: this.page.pageDesc
				},
				{
					vmid: "keywords",
					name: "keywords",
					content: this.page.pageTags
				},
				{
					vmid: "og:title",
					name: "og:title",
					content: this.page.pageTitle
				},
				{
					vmid: "og:description",
					name: "og:description",
					content: this.page.pageDesc
				},
				{
					vmid: "og:image",
					name: "og:image",
					content: this.fields.guide.image && this.fields.guide.image.url
				},
				{
					vmid: "og:url",
					name: "og:url",
					content: window.location.href.replace(location.hash, "")
				},
				{
					vmid: "twitter:card",
					name: "twitter:card",
					content: "summary_large_image"
				},
				{
					vmid: "twitter:title",
					name: "twitter:title",
					content: this.page.pageTitle
				},
				{
					vmid: "twitter:description",
					name: "twitter:description",
					content: this.page.pageDesc
				},
				{
					vmid: "twitter:image",
					name: "twitter:image",
					content: this.fields.guide.image && this.fields.guide.image.url
				}
			]
		};
	},
	created() {
		this.getContent();
	},
	data() {
		return {
			formModels: {
				journey: {
					model: "",
					valid: false,
					highlightInvalid: false,
					isRequired: true
				},
				postcode: {
					model: "",
					valid: false,
					highlightInvalid: false,
					isRequired: true,
					errorMsg: false,
					isPostcode: true
				},
				sector: {
					model: "",
					valid: false,
					highlightInvalid: false,
					isRequired: true
				}
			},
			fields: {
				guide: {
					image: {},
					text: []
				},
				journeys: null,
				sectors: null
			},
			options: {
				footer: {
					topLinks: [],
					twitterLink: {},
					facebookLink: {},
					instagramLink: {},
					linkedinLink: {},
					privacyLink: {},
					termsLink: {}
				}
			},
			page: {
				pageTitle: null,
				pageDesc: null,
				pageTags: null
			},
			loading: {
				page: true,
				route: false
			}
		};
	},
	computed: {
		...mapGetters({
			getPrismicPage: "prismicPage/getPrismicPageByName",
			getUserExists: "guideUser/getUserExists"
		})
	},
	methods: {
		...mapActions({
			fetchPrismicPage: "prismicPage/fetchPrismicPage",
			setupUser: "guideUser/setupUser",
			resetUser: "guideUser/resetState",
			setupDomesticUser: "guideUser/setupDomesticUser"
		}),
		validatePostcode(postcode) {
			// remove all spaces
			const postcodeTrimmed = postcode.trim();
			const strippedPostCode = postcodeTrimmed.split(" ").join("");
			// Complex regex for postcode UK Government Data Standard for postcodes
			const re = new RegExp(
				/(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z]))))[0-9][A-Z]{2}$/
			);

			// Check the postcode being passed in Matches the regex
			// Make Postcode Uppercase as it is not stored uppercase on the model
			const isMatch = strippedPostCode.toUpperCase().match(re);
			return isMatch ? true : false;

		},

		async getContent() {
			try {
				// Set Loading
				this.loading.page = true;

				// Get from store
				let page = this.getPrismicPage("guide_page");
				let options = this.getPrismicPage("options_page");

				// Get from API
				if (!page) page = await this.fetchPrismicPage("guide_page");
				if (!options) options = await this.fetchPrismicPage("options_page");

				// Check, parse, after-content
				if (page && options) {
					this.parsePrismicPage(page);
					this.parseOptions(options);
					this.afterContent();
				}
			} catch (error) {
				console.error(error);
			}
		},
		afterContent() {
			// Set Loading
			this.loading.page = false;
		},

		parsePrismicPage(page) {
			if (page) {
				this.fields.guide.image = page.data.guide_image;
				this.fields.guide.text = page.data.guide_text;
				this.fields.journeys = page.data.user_journeys.map(journey =>
					this.parseJourney(journey)
				);
				this.fields.sectors = page.data.guide_sectors.map(
					sector => sector.guide_sector
				);
			}
		},
		parseJourney(journey) {
			return {
				text: journey.user_journey_text,
				id: journey.user_journey_relationship.id,
				uid: journey.user_journey_relationship.uid
			};
		},
		parseOptions(options) {
			if (options) {
				//Footer
				this.options.footer.topLinks = options.data.top_links;
				this.options.footer.twitterLink = options.data.twitter_link;
				this.options.footer.facebookLink = options.data.facebook_link;
				this.options.footer.instagramLink = options.data.instagram_link;
				this.options.footer.linkedinLink = options.data.linkedin_link;
				this.options.footer.privacyLink = options.data.privacy_policy_link;
				this.options.footer.termsLink = options.data.terms_link;
			}
		},
		isjourneyDomestic() {
			return (
				this.formModels.journey.model ===
				`journey-${0}-${this.fields.journeys[0].uid}`
			);
		},

		changeRequired() {
			// Change required to false if it isnt the category field
			for (const key in this.formModels) {
				const formObj = this.formModels[key];
				if (key !== "journey") {
					formObj.isRequired = false;
				}
			}
		},

		checkFormModels() {
			// All Valid Bool
			let allValid = true;

			// Check if domestic selected
			if (this.isjourneyDomestic()) {
				this.changeRequired();
			}

			// Check and Set Valid for each model
			Object.values(this.formModels).forEach(formModel => {
				// Only validate fields that are required
				if (formModel.isRequired) {
					//form model has postcode field then apply validation to it.
					if (formModel.isPostcode) {
						formModel.valid =
							Boolean(formModel.model.length) &&
							this.validatePostcode(formModel.model);
					}
					// Default Check
					else formModel.valid = Boolean(formModel.model.length);

					if (!formModel.valid) allValid = false;
				}
			});

			// Return Valid
			return allValid;
		},
		highlightInvalidFormModel() {
			Object.values(this.formModels).forEach(formModel => {
				if (!formModel.valid) {
					// Highlight Invalid
					formModel.highlightInvalid = true;

					if (formModel.isPostcode) {
						formModel.errorMsg =
							"Please enter a valid UK postcode including a space";
					}

					window.setTimeout(() => (formModel.highlightInvalid = false), 2000);
				} else {
					formModel.errorMsg = "";
				}
			});
		},
		async submitJourney() {
			// Check Form Models
			this.highlightInvalidFormModel();
			if (!this.checkFormModels()) return;

			// Set Loading
			this.loading.route = true;

			// Check the User
			if (this.getUserExists) this.resetUser;

			// Get Single Journey
			const journey = this.fields.journeys.find(
				journey =>
					journey.uid ==
					this.getSelectedOptionData(this.$refs["formModelJourney"]).journeyUid
			);

			if (this.isjourneyDomestic()) {
				await this.setupDomesticUser([journey.uid, journey.text]);
			} else {
				await this.setupUser([
					journey.uid,
					journey.text,
					this.formModels.postcode.model,
					this.formModels.sector.model
				]);
			}

			// Go to Section
			await this.$router.push({
				name: "guide-section",
				params: {
					journey_uid: this.formModels.journey.model
				}
			});

			// Set Loading
			this.loading.route = false;
		},

		getSelectedOptionData(element) {
			if (element)
				return element.options[element.options.selectedIndex].dataset;
			return {};
		}
	}
};
</script>

<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.guide {
	background-color: $secondary;
	min-height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: stretch;

	main {
		padding-top: 2rem;
		padding-bottom: 2rem;
		padding-left: 0;
		padding-right: 0;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(lg) {
			min-height: 100vh;
		}
	}
	.remove-focus {
		box-shadow: none;
		outline: none;
	}
	.pagination {
		position: relative;
	}
}
</style>
