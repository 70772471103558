<template>
	<div class="next-step-card h-100">
		<div class="mb-4">
			<span class="rounded-circle badge-primary badge-step">
				{{ step }}
			</span>
		</div>
		<div class="flex-grow mb-3">
			<p class="font-weight-bold">{{ title }}</p>
		</div>
		<prismic-link :field="cardLink" class="btn-cta btn-outline-dark bg-white text-primary font-weight-bold">{{ buttonText }}</prismic-link>

	
	</div>
</template>

<script>
export default {
	props: {
		title: String,
		buttonText: String,
		step: Number,
		cardLink: Object
	},
};
</script>

<style>
.next-step-card{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #ffffff;
	border-radius: 8px;
	padding: 1.95rem 1.75rem;
}

.badge-step {
	font-size: 1.5rem;
	font-weight: bold;
	padding: 0.25rem 0.75rem;
}
</style>
