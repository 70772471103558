<template>
	<div>
		<div class="d-flex align-items-center mb-4">
			<h5
				class="pr-3 mr-2 mb-0 align-self-stretch border-right border-2 border-black font-weight-bold"
				v-if="Boolean(number)"
			>
				{{ number }}
			</h5>
			<prismic-rich-text
				:field="nextStep.text"
				class="prismic-small-copy prismic-last-p-mb-0"
			/>
		</div>
	</div>
</template>
<script>
export default {
	name: "ContentNextStep",
	props: ["number", "nextStep"]
};
</script>
