<template>
	<div>
		<!--- Icon --->
		<div class="position-absolute top-0 right-0">
			<div class="w-32 h-32 quartercircle-yellow quartercircle-right"></div>
			<div class="position-absolute top-0 right-0 z-10">
				<prismic-image class="h-16 w-16 mr-2 mt-2" :field="icon" />
			</div>
		</div>

		<!--- Content --->
		<div class="mr-16">
			<h3 class="h6 mb-2 font-weight-bold">{{ section.heading }}</h3>
			<prismic-rich-text
				class="prismic-small-copy prismic-line-breaks mt-4"
				:field="section.text"
			/>

			<div v-if="section.popupToggled && section.modals" class="w-100">
				<Modal
					:btnText="section.popupText"
					:modals="section.modals"
					:totalModals="section.modals.length"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import Modal from "@/components/Modal.vue";
export default {
	name: "ContentSection",
	components: {
		Modal
	},
	props: ["section", "icon"]
};
</script>
