<template>
	<div class="guide-next-steps transition-500">
		<GuideNav />

		<Loading :is-loading="loading.route" />

		<!-- steps 3 -->
		<!--- Main and Background --->
		<div class="guide-background with-sun"></div>

		<main class="container flex-auto">
			<div v-if="!loading.route && !loading.section" class="container relative pb-4 d-flex justify-content-center">
				<Pagination stepIndex="3" name="end" />
			</div>
			<!--- Card Wrapper --->
			<div
				v-if="!loading.route && getUserDno"
				class="guide-card w-100 w-md-75 w-2xl-60"
			>
				<div class="py-2 pl-4 mr-16">
					<!--- Icon --->
					<div class="top-0 right-0 position-absolute">
						<div
							class="w-32 h-32 quartercircle-yellow quartercircle-right"
						></div>
						<div class="top-0 right-0 z-10 position-absolute">
							<prismic-image
								class="w-16 h-16 mt-2 mr-2"
								:field="getUserJourney.icon"
							/>
						</div>
					</div>

					<!-- Content --->
					<h2 class="mb-3 h6 font-weight-bold">Next Steps</h2>

					<!--- Next Step Components --->
					<div
						class=""
						v-for="(nextStep, index) in nextSteps"
						:key="'nextStep-' + index"
					>
						<component
							v-if="nextStep"
							:is="nextStep.component"
							:nextStep="nextStep"
							:number="nextSteps.length > 1 ? index + 1 : 0"
						/>
					</div>

					<!--- Email Input --->
					<div v-if="getUserJourney.enableEmail">
						<label
							:for="$refs['formModelEmail']"
							class="mt-5 mb-1 d-block small font-weight-bold"
						>
							Send me my next steps
						</label>
						<div class="row align-items-center">
							<div class="mb-5 col-md-12 col-lg-7">
								<input
									ref="formModelEmail"
									:class="{
										'border-danger': formModels.email.highlightInvalid
									}"
									type="email"
									v-model="formModels.email.model"
									@keyup="formModelKeyupEnterSubmit"
									placeholder="Enter email address"
									class="px-4 py-2 text-sm border-2 rounded w-100"
								/>
							</div>

							<div class="mb-5 col-md-6 col-lg-5">
								<button
									@click="submitEmail"
									:disabled="!checkFormModels()"
									class="btn btn-rounded btn-primary guide-button"
									v-if="getUserJourney.enableEmail"
								>
									<small>Email Results</small>
								</button>
								<router-link
									:to="{ name: 'home' }"
									class="btn btn-rounded btn-primary guide-button"
									v-else
								>
									<small>Find out more from our FAQs</small>
								</router-link>
							</div>
						</div>
					</div>

					<!--- Section and Email Buttons --->
					<div class="d-flex">
						<button
							@click="previousSection"
							class="mr-2 btn btn-rounded btn-outline-primary guide-button"
						>
							<small>Back</small>
						</button>
					</div>
				</div>
			</div>
			<div v-else class="guide-card w-100 w-md-75 w-2xl-60">
				<h1>Failed</h1>
			</div>
		</main>

		<Footer v-if="options.footer" :data="options.footer" />
	</div>
</template>

<script>
import GuideNav from "@/components/guide/nav/GuideNav.vue";

import Loading from "@/components/Loading.vue";
import Footer from "@/components/Footer.vue";

import DnoNextStep from "@/components/guide/next-steps/DnoNextStep.vue";
import ContentNextStep from "@/components/guide/next-steps/ContentNextStep.vue";
import Pagination from "@/components/Pagination.vue";

import { mapGetters } from "vuex";

export default {
	name: "guide-next-steps",
	components: {
		GuideNav,
		Loading,
		Pagination,
		Footer
	},
	created() {
		this.getContent();
	},
	data() {
		return {
			formModels: {
				email: {
					model: "",
					isEmail: true,
					highlightInvalid: false
				}
			},
			nextSteps: [],
			options: {
				footer: {
					topLinks: [],
					twitterLink: {},
					facebookLink: {},
					instagramLink: {},
					linkedinLink: {},
					privacyLink: {},
					termsLink: {}
				}
			},
			loading: {
				nextSteps: true,
				route: false
			}
		};
	},
	computed: {
		...mapGetters({
			getPrismicPage: "prismicPage/getPrismicPageByName",
			getUserExists: "guideUser/getUserExists",

			getUserJourney: "guideUser/getUserJourney",
			getUserDno: "guideUser/getUserDno",
			getUserPostcode: "guideUser/getUserPostcode",
			getUserSector: "guideUser/getUserSector"
		})
	},
	methods: {
		getContent() {
			// Check the User
			if (!this.getUserExists) {
				this.$router.push({ name: "guide" });
				return;
			}

			// Set Loading
			this.loading.nextSteps = true;

			// Map NextSteps Components
			this.nextSteps = this.getUserJourney.nextSteps;
			this.nextSteps.forEach(nextStep => {
				switch (nextStep.type) {
					case "dno_next_step":
						nextStep["component"] = DnoNextStep;
						break;

					default:
						nextStep["component"] = ContentNextStep;
						break;
				}
			});

			// Check and after-content
			if (this.nextSteps) this.afterContent();
		},
		async afterContent() {
			// Get and Parse Options from Store
			this.parseOptions(this.getPrismicPage("options_page"));

			// Ensure getUserDno is finished
			await this.getUserDno;

			// Set Loading
			this.loading.nextSteps = false;
		},

		parseOptions(options) {
			if (options) {
				//Footer
				this.options.footer.topLinks = options.data.top_links;
				this.options.footer.twitterLink = options.data.twitter_link;
				this.options.footer.facebookLink = options.data.facebook_link;
				this.options.footer.instagramLink = options.data.instagram_link;
				this.options.footer.linkedinLink = options.data.linkedin_link;
				this.options.footer.privacyLink = options.data.privacy_policy_link;
				this.options.footer.termsLink = options.data.terms_link;
			}
		},

		formModelKeyupEnterSubmit(event) {
			if (event.code == "Enter") {
				this.highlightInvalidFormModel();
				this.submitEmail();
			}
		},

		validEmail(value) {
			// Add error messages later
			// if the field is not a valid email
			if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
				//return "This field must be a valid email";
				return false;
			}

			// All is good
			return true;
		},

		checkFormModels() {
			// Valid Bool
			let valid = true;

			// Check and Set Valid for each model
			Object.values(this.formModels).forEach(formModel => {
				// Check there is input
				formModel.valid = Boolean(formModel.model.length);

				// Check input is valid email
				if (formModel.valid && formModel.isEmail)
					formModel.valid = this.validEmail(formModel.model);

				if (!formModel.valid) valid = false;
			});
			// Return Valid
			return valid;
		},

		highlightInvalidFormModel() {
			Object.values(this.formModels).forEach(formModel => {
				if (!formModel.valid) {
					// Highlight Invalid
					formModel.highlightInvalid = true;

					window.setTimeout(() => (formModel.highlightInvalid = false), 1000);
				}
			});
		},

		async previousSection() {
			// Set Loading
			this.loading.route = true;

			// Router Push
			await this.$router.push({
				name: "guide-section",
				params: {
					journey_uid: this.$route.params["journey_uid"]
				}
			});

			// Set Loading
			this.loading.route = false;
		},
		submitEmail() {
			// Check Form Models
			if (!this.checkFormModels()) return;

			// Submit Drip Guide
			this.$submitDripGuide(
				this.formModels.email.model,
				this.getUserDno,
				this.getUserJourney,
				this.getUserPostcode,
				this.getUserSector
			)
				.then(response => {
					if (response.data.success) {
						this.$router.push({
							name: "guide-success",
							params: {
								journey_uid: this.$route.params["journey_uid"]
							}
						});
					}
					return response.data;
				})
				.catch(error => {
					// how to display the error
					console.error(error);
				});
		}
	}
};
</script>

<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.guide-next-steps {
	background-color: $secondary;
	min-height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: stretch;

	main {
		padding-top: 2rem;
		padding-bottom: 2rem;
		padding-left: 0;
		padding-right: 0;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(lg) {
			min-height: 100vh;
		}
	}
}
</style>
