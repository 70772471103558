<template>
	<nav
		id="nav"
		ref="navcontainer"
		class="navbar navbar-national bg-white navbar-expand-md navbar-light transition-250 sticky-top z-30 shadow px-md-5"
	>
		<a href="/#" class="safari-svg navbar-brand mx-auto ml-md-0 mr-md-auto">
			<Logo />
		</a>
		<div
			class="py-lg-3 text-center justify-content-center justify-content-md-end pl-2"
			id="navbar"
			ref="navbar"
		>
			<div
				class="navbar-nav align-items-end justify-content-center md:flex-row flex-col-reverse"
			>
				<a
					class="btn px-2 px-md-4 mr-2 nav-button font-weight-bold d-none d-md-block"
					href="#apply"
				>
					Reasons To Apply
				</a>
				<a
					class="btn px-2 px-md-4 mr-2 nav-button font-weight-bold"
					href="#faqs"
				>
					FAQs
				</a>

				<!-- <button
					class="btn px-2 px-md-4 mr-2 font-80 font-weight-bold"
					@click="$openDripForm"
				>
					{{ data.button1Text }}
				</button> -->
				<!-- <prismic-link
					class="btn btn-outline-dark btn-shadow text-primary px-2 px-md-4 font-80 font-weight-bold"
					:field="data.button2Link"
				>
					{{ data.button2Text }}
				</prismic-link> -->

				<router-link
					to="/guide"
					class="btn btn-outline-dark btn-shadow bg-white text-primary px-2 px-md-4 nav-button font-weight-bold"
				>
					Find out more
				</router-link>
			</div>
		</div>
	</nav>
</template>
<script>
import Logo from "@/components/icons/Logo.vue";

export default {
	name: "Nav",
	props: ["data"],
	components: {
		Logo
	},
	methods: {
		showNav() {
			let bodyClassList = document.body.classList;
			let navClassList = this.$refs.navbar.classList;
			let navContainerClassList = this.$refs.navcontainer.classList;

			bodyClassList.add("transition-250");
			bodyClassList.add("opacity-0");

			window.setTimeout(function() {
				window.scrollTo(0, 0);
				navClassList.toggle("show");

				navContainerClassList.toggle("bg-white");
				bodyClassList.toggle("overflow-hidden");
				bodyClassList.toggle("vh-100");
			}, 250);

			window.setTimeout(function() {
				bodyClassList.remove("opacity-0");
			}, 500);
		}
	}
};
</script>
<style lang="scss" scoped>
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.-ml-lg-50 {
	@include media-breakpoint-up(lg) {
		margin-left: -50%;
	}
}

.navbar {
	#navbar {
		width: auto;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
}
</style>
