import dnoLookupService from "../services/dno-lookup-service";

export default {
	install(Vue) {
		Vue.prototype.$fetchDnoUidByPostcode = async postcode => {
			const response = await dnoLookupService.getDnoByPostcode(postcode);

			if (
				"data" in response &&
				"success" in response.data &&
				response.data.success
			)
				return response.data.data;
			return "";
		};
	}
};
