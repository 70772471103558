<template>
	<div class="guide-success transition-500">
		<Nav v-if="fields.nav" :data="fields.nav" />

		<!--- Main and Background --->
		<div class="guide-background with-sun"></div>
		<main class="container flex-auto">
			<!--- Card Wrapper --->
			<div class="guide-card w-100 w-md-75 w-2xl-60">
				<!--- Content --->
				<div class="d-flex flex-column align-items-center mb-4 -mt-4">
					<div class="semicircle-yellow pb-5">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-24 w-48 object-contain"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="1.5"
								d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
							/>
						</svg>
					</div>

					<prismic-rich-text
						class="text-md mb-4"
						:field="fields.success_text"
					/>
					<router-link to="/" class="btn btn-rounded btn-primary guide-button">
						<small class="text-md">Back to site</small>
					</router-link>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Nav from "@/components/Nav.vue";

export default {
	name: "success",
	components: {
		Nav
	},
	created() {
		this.getContent();
	},
	data() {
		return {
			fields: {
				nav: {
					button1Text: null,
					button2Text: null,
					button2Link: {}
				},
				success_text: []
			},
			page: {
				pageTitle: null,
				pageDesc: null
			}
		};
	},
	computed: {
		...mapGetters({
			getPrismicPage: "prismicPage/getPrismicPageByName"
		})
	},
	methods: {
		...mapActions({
			fetchPrismicPage: "prismicPage/fetchPrismicPage"
		}),
		async getContent() {
			// Get from store
			let options = this.getPrismicPage("options_page");

			// Get from API
			if (!options) options = await this.fetchPrismicPage("options_page");

			// Check, parse, after-content
			if (options) {
				this.parseOptions(options);
			}
		},
		parseOptions(options) {
			if (options) {
				//Nav
				this.fields.nav.button1Text = options.data.nav_button_1_text;
				this.fields.nav.button2Text = options.data.nav_button_2_text;
				this.fields.nav.button2Link = options.data.nav_button_2_link;
				this.fields.success_text = options.data.success_text;
			}
		}
	}
};
</script>

<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.success-icon {
	width: 1rem;
}
.success-text {
	font-size: 2rem;
	margin-bottom: 4rem;
	margin-top: 3rem;
}

.guide-success {
	background-color: $secondary;
	min-height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: stretch;

	main {
		padding-top: 2rem;
		padding-bottom: 2rem;
		padding-left: 0;
		padding-right: 0;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(lg) {
			min-height: 100vh;
		}
	}
}
</style>
